<template>
    <ion-page>
        <ion-header>
            <ion-toolbar>
                <ion-buttons slot="start">
                    <ion-back-button defaultHref="/" :text="$t('nav.back')">Back</ion-back-button>
                </ion-buttons>
                <ion-title class="title">{{ $t('dashboard.support.contact') }}</ion-title>
                <ion-buttons slot="end">
                    <ion-button @click="openMailto">{{ $t('nav.submit') }}</ion-button>
                </ion-buttons>
            </ion-toolbar>
        </ion-header>
        <ion-content class="ion-margin">
            <h1 class="ion-padding">
                {{ $t('dashboard.support.in_touch') }}
            </h1>
            <sub class="ion-padding">{{ $t('dashboard.support.fill_form_request') }}</sub>
            <form class="ion-margin feedback-form">
                <ion-item class="ion-padding-bottom">
                    <ion-input class="ion-padding" type="email" :placeholder="$t('dashboard.support.return_email')"
                        v-model="form.email" required></ion-input>
                </ion-item>
                <div class="ion-padding-top">
                    <ion-label class="ion-padding" style="font-weight:bold;">{{ $t('dashboard.support.question')
                        }}</ion-label>
                    <ion-item>
                        <ion-textarea class="custom" v-model="form.message" :rows="7"
                            :placeholder="$t('dashboard.support.response_placeholder')" autoGrow=" true"></ion-textarea>
                    </ion-item>
                </div>
            </form>
        </ion-content>
    </ion-page>
</template>

<script>
import { useUserStore } from '~/store/userStore';

export default {
    name: 'ContactUs',
    data() {
        return {
            form: {
                email: '',
                message: ''
            }
        };
    },
    methods: {
        openMailto() {
            const uStore = useUserStore();

            const now = new Date();
            const date = `${now.getDate().toString().padStart(2, '0')}/${(now.getMonth() + 1).toString().padStart(2, '0')}/${now.getFullYear().toString().slice(-2)}`;
            const time = `${now.getHours().toString().padStart(2, '0')}:${now.getMinutes().toString().padStart(2, '0')}`;
            const identifier = `[${uStore.user.id}.${time}.${date}]`;
            const subject = encodeURIComponent(`IASupport: Contact Us Form Submission - ${identifier}`);
            const body = encodeURIComponent(`Return Email: ${this.form.email}\nSignup Email: ${uStore.user.email}\nMessage: ${this.form.message}`);
            window.location.href = `mailto:support@cloudcompany.ca?subject=${subject}&body=${body}`;
        }
    }
}
</script>

<style scoped>
.title {
    text-align: center;
}

ion-item {
    --detail-icon-opacity: 1;
    --detail-icon-font-size: 14px;
}

.feedback-form {
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
</style>