<template>
    <ion-page>
        <ion-header>
            <ion-toolbar>
                <ion-buttons slot="start">
                    <ion-back-button defaultHref="/" :text="$t('nav.back')"></ion-back-button>
                </ion-buttons>
                <ion-buttons slot="end">
                    <ion-button :strong="true" class="ion-start-start" @click="saveUser()">{{ $t('nav.save')
                        }}</ion-button>
                </ion-buttons>
            </ion-toolbar>
        </ion-header>
        <ion-content class="ion-margin">
            <h1 class="ion-padding">
                {{ $t('dashboard.account.userInfo.email.header') }}
            </h1>
            <sub class="ion-padding">{{ $t('dashboard.account.userInfo.email.enter_email') }}</sub>
            <ion-list class="ion-margin-bottom" ion-padding-top>
                <ion-item class="ion-padding-end">
                    <ion-input v-model="email"></ion-input>
                </ion-item>
            </ion-list>
        </ion-content>
    </ion-page>
</template>

<script>
import { useUserStore } from '@/store/userStore'

const uStore = useUserStore()

export default {
    data() {
        return {
            email: ''
        }
    },
    created() {
        if (uStore.user) {
            this.email = uStore.user.email
        }
    },
    methods: {
        async saveUser() {
            try {
                const updatedUser = await uStore.updateUser({ email: this.email });
                this.email = updatedUser.email;
            } catch (err) {
                console.log('Error saving user:', err);
            }
        },
    }
}
</script>

<style scoped>
ion-item {
    --detail-icon-opacity: 1;
    --detail-icon-font-size: 14px;
}
</style>