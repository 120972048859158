<template>
    <ion-page>
        <ion-header>
            <ion-toolbar>
                <ion-title class="ion-text-center">Password Reset</ion-title>
            </ion-toolbar>
        </ion-header>
        <ion-content>
            <div class="ion-margin" v-if="!passwordReset">
                <h3 class="ion-padding">Please select a new password</h3>
                <div class="ion-padding">
                    <PasswordInput @password-validation="passwordPassed = $event" @update:password="password = $event">
                    </PasswordInput>
                </div>
                <p class="error-text" v-if="errorText">{{ errorText }}</p>
                <ion-button expand="block" color="primary" @click="resetPassword" :disabled="!passwordPassed">Reset
                    Password</ion-button>
            </div>
            <div class="ion-margin" v-else>
                <p class="ion-padding center-text">Your password has been reset. Please login with your new password.
                </p>
                <p class="ion-padding center-text">You may now close this window.</p>
            </div>
        </ion-content>
    </ion-page>
</template>

<script>
import PasswordInput from '@/components/signup/PasswordInput.vue';

export default {
    components: {
        PasswordInput
    },
    data() {
        return {
            token: null,
            password: '',
            passwordPassed: false,
            passwordReset: false,
            errorText: ''
        };
    },
    watch: {
        '$route.query.token': {
            immediate: true,
            handler(newToken) {
                if (newToken) {
                    this.token = newToken;
                }
            }
        }
    },
    methods: {
        async resetPassword() {
            try {
                const response = await fetch(`${this.$config.public.API_BASE_URL}auth/emails/reset_user_password/${this.token}`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        new_password: this.password
                    })
                });
                const data = await response.json();
                console.log(data);
                if (response['status'] === 200) {
                    this.passwordReset = true;
                } else {
                    console.error(data);
                    this.errorText = "There was an error resetting your password. Please resend the email.";
                }
            } catch (error) {
                console.error(error);
            }
        }
    }
};
</script>

<style scoped>
.error-text {
    color: #ff0000;
}
</style>