<template>
    <ion-modal ref="signupActionModal" :isOpen="isOpen" @didDismiss="closeModal">
        <modalToolbar class="ion-margin-top" :nodeTitle="''" @closeModal="closeModal" />
        <div class="modal-content">
            <ArkitectLogo class="logo" size="md"></ArkitectLogo>
            <h1 class="center-text keep-learning">{{ $t('home.signup.action.header') }}</h1>
            <div class="center-sub-container">
                <p>{{ $t('home.signup.action.sub.1') }}
                    <ion-text style="text-decoration: underline; font-weight: 600;" color="primary">{{
                        $t('home.signup.action.sub.2')
                    }}</ion-text>
                    {{ $t('home.signup.action.sub.3') }}
                </p>
                <div class="ion-padding">
                    <EmailInput @update:email=" email = $event" @update:isEmailTaken="isTaken = $event" fontSize="sm">
                    </EmailInput>
                    <ion-button expand="block" color="primary" class="ion-padding-top ion-padding-bottom"
                        @click="updateUserData()" :disabled="isTaken"> {{ $t('home.signup.action.button') }}
                    </ion-button>
                </div>
            </div>
        </div>
        <div class="ion-padding-start ion-padding-end">
            <Swiper class="swiper" :modules="[SwiperAutoplay, SwiperPagination, SwiperEffectCreative]"
                :effect="'effect-cards'" :slides-per-view="1" :loop="true" :pagination="true"
                :dir="$i18n.localeProperties.dir" :autoplay="{
                    delay: 3000,
                    disableOnInteraction: false
                }">
                <SwiperSlide v-for="(header, index) in $tm('welcomemodal.headers')" :key="'slide-' + index">
                    <TextBox class="TextBox" :cardImage="cardImages[index]" :backgroundColor="'transparent'"
                        :header="$rt(header)" :text="$tm('welcomemodal.texts')[index]">
                    </TextBox>
                </SwiperSlide>
            </Swiper>
        </div>
    </ion-modal>
</template>

<script>
import ArkitectLogo from '../welcome/ArkitectLogo.vue';
import { useGuestStore } from '@/store/guestStore';
import modalToolbar from '~/components/modal/modalToolbar.vue';
import { useModalStore } from '@/store/modalStore';
import Sso from '~/components/Sso.vue'
import EmailInput from './EmailInput.vue';
import TextBox from '../welcome/TextBox.vue';

const mStore = useModalStore();
const gStore = useGuestStore();

export default {
    name: 'signupActionModal',
    components: {
        Sso,
        modalToolbar,
        EmailInput,
        TextBox,
        ArkitectLogo
    },
    data() {
        return {
            email: '',
            isTaken: true,
            cardImages: ['../welcome/learn.png', '../welcome/certified.png', '../welcome/affordable.png']
        }
    },
    props: {
        isOpen: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        closeModal() {
            if (mStore.isSignupActionModalOpen) {
                mStore.toggleSignupActionModal(false);
            }
        },
        updateUserData() {
            gStore.updateUserSignupData({ email: this.email });
            this.isTaken = true;
            mStore.toggleSignupActionModal(false);
            mStore.toggleSignupModal(true);
        }
    }
}
</script>

<style scoped>
ion-modal {
    padding-top: 0%;
}

ion-modal::part(backdrop) {
    background: rgb(54, 55, 57);
    opacity: 1;
}

.modal-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    padding: 1rem;
}

.logo {
    margin-top: -2rem;
    z-index: 1000;
}

.keep-learning {
    font-size: 1.4em;
    margin: 0.5rem 0;
}

.center-sub-container {
    text-align: center;
}

.swiper {
    padding-top: 2rem;
    width: 100%;
    margin-bottom: 2rem;
}

@media screen and (max-width: 600px) {
    .swiper {
        padding-top: .5rem;
    }
}
</style>
