<template>
    <div>
        <button @click="launch" @contextmenu.prevent="" @mousedown.prevent="" :disabled="isDisabled"
            :class="{ 'button-74': true, 'disabled': isDisabled }">
            {{ $t('home.launch') }}
        </button>
    </div>
</template>

<script>
import { useGuestStore } from '../store/guestStore';

export default {
    data() {
        return {
            isDisabled: false
        };
    },
    methods: {
        launch() {
            useGuestStore().submitActivity("launch_pressed", 'From Lattice')
            this.isDisabled = true;
            setTimeout(() => {
                this.fadeOutButton();
            }, 1000);
        },
        fadeOutButton() {
            const button = this.$el.querySelector('button');
            button.style.opacity = '1';
            let opacity = 1;
            const fadeInterval = setInterval(() => {
                opacity -= 0.025;
                button.style.opacity = opacity.toString();
                if (opacity <= 0) {
                    clearInterval(fadeInterval);
                }
            }, 20);
        }
    }
};
</script>

<style scoped>
.button-74 {
    background-color: var(--ion-color-primary);
    border: 2px solid var(--ion-color-primary-contrast);
    border-radius: 16px;
    box-shadow: #00000071 -4px 4px 2px 0;
    color: var(--ion-color-primary-contrast);
    cursor: pointer;
    display: inline-block;
    font-weight: 600;
    font-size: 1.2em;
    padding: 0 1em;
    line-height: 2em;
    text-align: center;
    text-decoration: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    scale: 1.5;
    position: relative;
    animation-delay: 3500ms;
}

.button-74:active:not(.disabled) {
    box-shadow: #00000071 -2px 2px 0 0;
    transform: translate(0px, 1px);
}

.button-74.disabled {
    cursor: not-allowed;
    animation: none;
    background-color: grey;
}

@media (min-width: 600px) {
    .button-74 {
        font-size: 1.6em;
        padding: 0 25px;
    }
}
</style>