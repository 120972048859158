import { Capacitor } from "@capacitor/core";
import * as PIXI from "pixi.js";
import gsap from "gsap";

import { useNodeStore } from "../store/nodeStore";
import { useUserStore } from "../store/userStore";
import { useModalStore } from "../store/modalStore";
import { useVngStore } from "../store/vngStore";

export const bucketBaseUrl = "https://cdn.cloudcompany.ca/websrc";
let currentAsteroid: SVGGElement | null = null;

interface Star {
    x: number;
    y: number;
    size: number;
    color?: string;
    duration: number;
}

const starColors = [
    "#efe6c5", // Gold
    "#cce2ee", // Moccasin
    "#e9c0c0", // Pale Green
    "#efe7e7" // Light Sea Green
];

export const fadeInSprite = (sprite: PIXI.Sprite | PIXI.Text): void => {
    const fadeInDuration = 1000;
    const startTime = Date.now();

    const animate = () => {
        const currentTime = Date.now();
        const deltaTime = currentTime - startTime;
        sprite.alpha = deltaTime / fadeInDuration;

        if (sprite.alpha < 1) {
            requestAnimationFrame(animate);
        } else {
            sprite.alpha = 1;
        }
    };

    requestAnimationFrame(animate);
};

export const tweenShip = (
    ship: PIXI.Sprite,
    label?: PIXI.Text
): GSAPTimeline => {
    const tweenDuration = 5 + Math.random();
    const tweenVerticalDistance = 5;

    if (label) {
        label.x = ship.x;
        label.y = ship.y - ship.height / 2;
    }

    const tb = gsap.timeline({
        repeat: -1,
        ease: "power1.inOut",
        onUpdate: label ? updateLabelPosition : undefined
    });

    function updateLabelPosition() {
        if (!label) return;
        label.x = ship.x;
        label.y = ship.y - ship.height / 1.5;
    }

    tb.to(ship, {
        y: `+=${tweenVerticalDistance}`,
        rotation: 0.05,
        duration: tweenDuration
    }).to(ship, {
        y: `-=${tweenVerticalDistance}`,
        rotation: -0.05,
        duration: tweenDuration
    });

    return tb;
};

export const createShipTrail = (
    app: PIXI.Application,
    ship: PIXI.Sprite,
    trailFrequency: number = 100
): { intervalId: number } => {
    const createParticle = (): void => {
        if (!ship || !ship.texture || !ship.texture.valid || !app || !app.stage)
            return;

        const particle = new PIXI.Graphics();
        particle.beginFill(0xffffff);
        particle.drawCircle(0, 0, 3);
        particle.endFill();
        particle.x = ship.x - ship.width / 3;
        const randomYOffset = Math.random() * 10 - 5;
        particle.y = ship.y + randomYOffset;
        particle.alpha = 0.7;
        particle.zIndex = 1;
        app.stage.addChild(particle);

        gsap.to(particle, {
            alpha: 0,
            x: particle.x - 50,
            duration: 0.5,
            onComplete: () => {
                particle.destroy();
            }
        });
    };

    const intervalId = setInterval(
        createParticle,
        trailFrequency
    ) as unknown as number;
    return { intervalId };
};

export async function orbitGuestNode(): Promise<void> {
    const vngStore = useVngStore();
    const nStore = useNodeStore();
    vngStore.ship.setOrReturnShipTexture(true);
    vngStore.updateMinZoomLevel();
    await useVngStore()
        .ship.setScale(1.25)
        .then(() => {
            setTimeout(() => {
                vngStore.ship.startOrbitingTarget(
                    nStore.layouts.nodes[-5].x,
                    nStore.layouts.nodes[-5].y,
                    -5
                );
            }, 150);
        });
}

export async function orbitUserNode(skipZoom?: Boolean): Promise<void> {
    const vngStore = useVngStore();
    const nStore = useNodeStore();
    const uStore = useUserStore();

    if (!skipZoom) {
        vngStore.ship.setOrReturnShipTexture(true);
        vngStore.updateMinZoomLevel();
    }

    await useVngStore()
        .ship.setScale(0.5)
        .then(() => {
            setTimeout(() => {
                const currentNode = uStore.user?.currentnode || 0;
                vngStore.ship.startOrbitingTarget(
                    nStore.layouts.nodes[currentNode].x,
                    nStore.layouts.nodes[currentNode].y,
                    currentNode
                );
            }, 175);
        });
}

export async function spawnAsteroid(
    svgLatticeElement: SVGElement,
    ship: any
): Promise<void> {
    if (currentAsteroid || useModalStore().isNodeOpen) {
        setTimeout(() => {
            spawnAsteroid(svgLatticeElement, ship);
        }, 5000);
        return;
    }

    const asteroidRadius = 25;

    currentAsteroid = document.createElementNS(
        "http://www.w3.org/2000/svg",
        "g"
    );
    currentAsteroid.setAttribute("class", "asteroid");

    const asteroid = document.createElementNS(
        "http://www.w3.org/2000/svg",
        "image"
    );
    asteroid.setAttribute("x", "-15");
    asteroid.setAttribute("y", "-15");
    asteroid.setAttribute("width", "30");
    asteroid.setAttribute("height", "30");
    asteroid.setAttribute("href", `/images/asteroid.png`);

    const asteroidShadow = document.createElementNS(
        "http://www.w3.org/2000/svg",
        "circle"
    );
    asteroidShadow.setAttribute("cx", `-${asteroidRadius * 0.15}`);
    asteroidShadow.setAttribute("cy", `${asteroidRadius * 0.15}`);
    asteroidShadow.setAttribute("r", "15");
    asteroidShadow.setAttribute("fill", "url(#shadow-gradient)");

    currentAsteroid.appendChild(asteroidShadow);
    currentAsteroid.appendChild(asteroid);
    svgLatticeElement.insertBefore(currentAsteroid, ship);
    gsap.set(currentAsteroid, { x: randomX(), y: randomY() });

    gsap.to(asteroid, {
        rotation: 360,
        duration: 3 + Math.random() * 12,
        alignContent: "center",
        transformOrigin: "center",
        repeat: -1,
        ease: "none"
    });

    gsap.fromTo(
        currentAsteroid,
        { scale: 1 },
        {
            x: randomX(),
            y: randomY(),
            scale: 0,
            duration: 3 + Math.random() * 12,
            onComplete: () => {
                currentAsteroid?.remove();
                currentAsteroid = null;
                spawnAsteroid(svgLatticeElement, ship);
            }
        }
    );
}

export function generateStarPositions(): Star[] {
    const starCount = 300;
    const starSize = 5;
    const minDistance = starSize * 1.5;
    const originBuffer = 100;
    let stars: Star[] = [];

    for (let i = 0; i < starCount; i++) {
        let newX, newY;
        let validPosition = false;

        while (!validPosition) {
            newX = randomX();
            newY = randomY();

            const distanceFromOrigin = Math.sqrt(
                Math.pow(newX, 2) + Math.pow(newY, 2)
            );

            if (distanceFromOrigin < originBuffer) {
                continue;
            }

            validPosition = true;
            for (const existingStar of stars) {
                const distance = Math.sqrt(
                    Math.pow(newX - existingStar.x, 2) +
                        Math.pow(newY - existingStar.y, 2)
                );

                if (distance < minDistance) {
                    validPosition = false;
                    break;
                }
            }
        }

        stars.push({
            x: newX ?? 0,
            y: newY ?? 0,
            size: 1 + Math.random() * 3,
            color: starColors[Math.floor(Math.random() * starColors.length)],
            duration: 3 + Math.random() * 7
        });
    }
    return stars;
}

function randomX(): number {
    return Math.floor(Math.random() * 4000 - 2000);
}

function randomY(): number {
    return Math.floor(Math.random() * 4000 - 2000);
}

export function minZoomLevel() {
    const userStore = useUserStore();
    if (userStore.user?.is_staff) {
        return 0.25;
    }
    if (!userStore.loggedIn) {
        return 1;
    }
    return 0.8;
}

export function maxZoomLevel() {
    const userStore = useUserStore();
    if (userStore.user?.is_staff) {
        return 1.25;
    }
    if (!userStore.loggedIn || Capacitor.getPlatform() !== "web") {
        return 1.05;
    } else {
        return 1.15;
    }
}

export function fetchVngComp(
    vngComp: any,
    setVngCompCallback: (
        vngComp: any,
        graphElement: HTMLElement,
        svgLatticeElement: HTMLElement
    ) => void
): void {
    const graphElement = vngComp?.$el;
    const svgLatticeElement = document.querySelector(
        ".v-ng-viewport.svg-pan-zoom_viewport"
    ) as HTMLElement;

    if (graphElement && svgLatticeElement) {
        setVngCompCallback(vngComp, graphElement, svgLatticeElement);
        return;
    }

    throw new Error("VngComp elements not found. Ensure DOM is ready.");
}
