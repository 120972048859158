<template>
    <div class="center-text">
        <Swiper class="swiper" :allowTouchMove="false" :modules="[SwiperPagination, SwiperEffectCreative]"
            effect="cards" slides-per-view="1" :pagination="false" :dir="$i18n.localeProperties.dir" @swiper="onSwiper">
            <SwiperSlide :key="'career-slide'">
                <h1>{{ $t('welcomemodal.slide1.title') }}</h1>
                <p>{{ $t('welcomemodal.slide1.text') }}</p>
                <ion-grid class="ion-padding">
                    <ion-row>
                        <ion-col size="6" class="button-center">
                            <CareerButton @click="slideNext" buttonText="IT Admin" src="images/welcome/IT.png" />
                        </ion-col>
                        <ion-col size="6" class="button-center">
                            <CareerButton :disabled="true" @click="slideNext" buttonText="Cybersecurity"
                                src="images/welcome/hack.png" />
                        </ion-col>
                    </ion-row>
                    <ion-row>
                        <ion-col size="6" class="button-center">
                            <CareerButton :disabled="true" @click="slideNext" buttonText="Data Analyst"
                                src="images/welcome/analytics.png" />
                        </ion-col>
                        <ion-col size="6" class="button-center">
                            <CareerButton :disabled="true" @click="slideNext" buttonText="Networking"
                                src="images/welcome/network.png" />
                        </ion-col>
                    </ion-row>
                </ion-grid>
            </SwiperSlide>
            <SwiperSlide :key="'cert-slide'">
                <h1>{{ $t('welcomemodal.slide2.title') }}</h1>
                <p>{{ $t('welcomemodal.slide2.text') }}</p>
                <QuestlineSelection @QuestlineSelected="slideNext" class="selector"></QuestlineSelection>
            </SwiperSlide>
            <SwiperSlide :key="'cert-slide'">
                <h1>{{ $t('welcomemodal.slide3.title') }}</h1>
                <p>{{ $t('welcomemodal.slide3.text') }}</p>
                <div class="ship-image-container">
                    <transition name="fade" mode="out-in">
                        <ion-img :key="shipID" class="ship-image" :src="shipImageSrc"></ion-img>
                    </transition>
                </div>
                <div class="ion-padding">
                    <ion-button @click="updateShipID('prev')" class="ion-padding">
                        <ion-icon :icon="chevronBackOutline"></ion-icon>
                    </ion-button>
                    <ion-button @click="updateShipID('next')" class="ion-padding">
                        <ion-icon :icon="chevronForwardOutline"></ion-icon>
                    </ion-button>
                </div>
                <div class="button-container">
                    <ion-button @click="handleClose()">
                        <b class="ion-padding-end">Launch lesson</b>
                        <ion-icon :icon="rocketOutline"></ion-icon>
                    </ion-button>
                    <sub>{{ $t('welcomemodal.slide3.sub') }}</sub>
                </div>
            </SwiperSlide>
        </Swiper>
    </div>
</template>

<script lang="ts">
import { ref } from 'vue';
import { useVngStore } from '../../store/vngStore';
import QuestlineSelection from '../signup/QuestlineSelection.vue';
import CareerButton from './CareerButton.vue';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Pagination, EffectCreative } from 'swiper/modules';
import admin from '../../assets/lotties/admin/admin.json';
import { bucketBaseUrl } from "../../utils/latticeUtils";
import { chevronBackOutline, chevronForwardOutline, rocketOutline } from 'ionicons/icons';

export default {
    components: {
        Swiper,
        SwiperSlide,
        QuestlineSelection,
        CareerButton
    },
    emits: ['close'],
    setup() {
        const swiperInstance = ref(null);

        const onSwiper = (swiper) => {
            swiperInstance.value = swiper;
        };

        const slideNext = () => {
            if (swiperInstance.value) {
                swiperInstance.value.slideNext();
            }
        };

        return {
            onSwiper,
            slideNext,
            SwiperPagination: Pagination,
            SwiperEffectCreative: EffectCreative,
            admin,
            bucketBaseUrl,
            chevronBackOutline,
            chevronForwardOutline,
            rocketOutline
        };
    },
    data() {
        return {
            shipID: '6',
        }
    },
    methods: {
        updateShipID(dir: 'next' | 'prev') {
            const shipID = parseInt(this.shipID);
            if (dir === 'next') {
                this.shipID = (shipID % 6 + 1).toString();
            } else if (dir === 'prev') {
                this.shipID = ((shipID - 2 + 6) % 6 + 1).toString();
            }
        },
        handleClose() {
            const vngStore = useVngStore();
            vngStore.ship.setOrReturnShipTexture(true, parseInt(this.shipID));
            this.$emit('close');
        }
    },
    computed: {
        shipImageSrc() {
            return `${this.bucketBaseUrl}/General/Assets/Ships/${this.shipID}.png`;
        }
    }
}
</script>

<style scoped>
.button-container {
    text-align: center;
}

.button-container sub {
    display: block;
    margin-top: 1rem;
    font-size: 0.8rem;
}

.button-center {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    text-align: center;
}

.career-buttons-container {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
}

.career-buttons-container>* {
    flex: 1 1 calc(50% - 16px);
    box-sizing: border-box;
}

@media (max-width: 600px) {
    .career-buttons-container>* {
        flex: 1 1 100%;
    }
}

.ship-image-container {
    width: 12rem;
    height: 12rem;
    margin: 0 auto;
    overflow: hidden;
}

.ship-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.1s ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}
</style>
