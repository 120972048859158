<template>
    <ion-modal :isOpen="modalOpen" :backdrop-dismiss="false" :handle="false" @didDismiss="closeModal(true)">
        <div class="modal-container">
            <modalToolbar :nodeTitle="$t('home.signup.title')" :hideClose="hideClose" @closeModal="closeModal" />
            <ion-content v-if="!isLoading" :forceOverscroll="false" class="ion-padding">
                <Swiper ref="signupSwiper" @slideChange="onSlideChange" :modules="[SwiperEffectCreative]"
                    :slides-per-view="1" :loop="false" :allowTouchMove="false" :dir="$i18n.localeProperties.dir">
                    <SwiperSlide v-for="(slideComponent, index) in slides" :key="index">
                        <component :is="slideComponent" @didSubscribe="setSubscribe($event)"
                            @loginComplete="closeModal(true)">
                        </component>
                    </SwiperSlide>
                </Swiper>
            </ion-content>
            <loading v-if="isLoading" />
        </div>
    </ion-modal>
</template>


<script>
import { useGuestStore } from '~/store/guestStore';
import { useModalStore } from '~/store/modalStore';
import modalToolbar from '~/components/modal/modalToolbar.vue';
import GlobalIcon from '~/components/GlobalIcon.vue';
import { logoGoogle, logoApple } from 'ionicons/icons';
import DetailsSlide from './signup/DetailsSlide.vue';
import EmailSlide from './signup/EmailSlide.vue';
import SubscriptionSlide from './signup/SubscriptionSlide.vue';
import SigningInSlide from './signup/SigningInSlide.vue';
import { actionSheetController } from '@ionic/vue';
import { orbitUserNode } from "../utils/latticeUtils";

const mStore = useModalStore();
const gStore = useGuestStore();

export default {
    name: 'SignupModal',
    components: {
        GlobalIcon,
        DetailsSlide,
        EmailSlide,
        SubscriptionSlide,
        SigningInSlide,
        modalToolbar
    },
    setup() {
        return {
            slides: [EmailSlide, DetailsSlide, SubscriptionSlide, SigningInSlide],
            logoGoogle,
            logoApple
        };
    },
    data() {
        return {
            modalOpen: true,
            isLoading: false,
            subscribed: false,
            currentSlideIndex: 0
        }
    },
    computed: {
        hideClose() {
            return this.currentSlideIndex >= 3;
        }
    },
    methods: {
        async closeModal(ssoOverride = false) {
            if (this.isLoading === true || ssoOverride === true) {
                this.isLoading = false;
                this.modalOpen = false;
                mStore.toggleSignupModal(false);
                return;
            }
            // Issue Ari - Not sure how I could bring in the i18n here
            const actionSheet = await actionSheetController.create({
                header: 'Are you sure? Your progress will be lost.',
                buttons: [
                    {
                        text: 'Yes',
                        role: 'confirm',
                        handler: () => {
                            mStore.toggleSignupModal(false);
                        },
                    },
                    {
                        text: 'No',
                        role: 'cancel',
                    },
                ],
            });
            actionSheet.present();
            const { role } = await actionSheet.onWillDismiss();
            if (role === 'confirm') {
                this.$emit('update:signupModal', false);
            }
        },
        setSubscribe(subscribed) {
            this.subscribed = subscribed;
        },
        handleDismiss() {
            return new Promise(resolve => {
                resolve(true);
                this.closeModal()
            });
        },
        onSlideChange(swiper) {
            this.currentSlideIndex = swiper.activeIndex;
            const finalSlideIndex = this.slides.length - 1;
            if (swiper.activeIndex === finalSlideIndex) {
                this.signUserIn();
            }
        },
        async signUserIn() {
            try {
                await gStore.signUserInFromID(this.subscribed);
                orbitUserNode();
                this.closeModal(true);
            } catch (err) {
                console.log(err);
            }
        }
    }
}
</script>

<style scoped>
ion-input {
    color: #414042;
}

.swiper {
    width: 100%;
    height: 100%;
}

.description-text ion-label {
    color: #414042;
    font-size: 1.25rem;
    background-color: rgba(255, 255, 255, 0.75);
    padding: 2%;
    border-radius: 7px;
    display: block;
    border: 2px solid white;
    text-align: center;
}

.modal-container {
    z-index: 2;
    position: relative;
    height: 100%;
    --ion-background-color: rgb(246, 248, 248);
    color: #414042;
}

.modal-background {
    z-index: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-repeat: no-repeat;
    background-size: cover;
}

.clear-button {
    justify-content: center;
    align-items: center;
    width: 100%;
}
</style>
