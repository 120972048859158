<template>
    <ion-page>
        <ion-header>
            <ion-toolbar>
                <ion-buttons slot="start">
                    <ion-back-button defaultHref="/" :text="$t('nav.back')"></ion-back-button>
                </ion-buttons>
            </ion-toolbar>
        </ion-header>
        <ion-content class="ion-margin">
            <h1 class="ion-padding">
                {{ $t('dashboard.login.delAccount.header') }}
            </h1>
            <div class="ion-padding">
                <ion-card class="ion-margin">
                    <ion-card-title class="ion-padding">
                        {{ $t('dashboard.login.delAccount.notice') }}
                    </ion-card-title>
                    <ion-card-content class="ion-padding">
                        {{ $t('dashboard.login.delAccount.warning') }}
                    </ion-card-content>
                </ion-card>
            </div>
            <sub class="ion-padding">{{ $t('dashboard.login.delAccount.enter_email') }}</sub>
            <div class="ion-margin">
                <ion-input class="custom" v-model="email"></ion-input>
            </div>
            <ion-button class="ion-padding" color="warning" expand="full" :disabled="!correct" @click="deleteUser()">{{
                $t('dashboard.login.delAccount.delete') }}</ion-button>
        </ion-content>
    </ion-page>
</template>

<script>
import { useUserStore } from '@/store/userStore'
import { orbitGuestNode } from "../../../utils/latticeUtils";

const uStore = useUserStore()

export default {
    data() {
        return {
            correct: false,
            email: ''
        }
    },
    methods: {
        async deleteUser() {
            try {
                await $fetch(this.$config.public.API_BASE_URL + 'user/' + uStore.user.id + '/', {
                    method: 'DELETE',
                    headers: new Headers({
                        'Authorization': 'Bearer ' + uStore.accessToken,
                    }),
                }).then(res => {
                    uStore.logout()
                    orbitGuestNode();
                })
            } catch (err) {
                console.log(err)
            }
        }
    },
    watch: {
        email: {
            immediate: true,
            handler: function () {
                if (this.email != uStore.user.email) {
                    this.correct = false
                } else {
                    this.correct = true
                }
            },
        }
    }
}
</script>

<style scoped>
ion-card {
    margin-left: auto;
    margin-right: auto;
}
</style>