import { subscribe } from "diagnostics_channel";
import { link } from "fs";

export default defineI18nLocale(async (locale) => {
    return {
        nav: {
            back: "Back",
            save: "Save",
            submit: "Submit"
        },
        home: {
            login: {
                title: "Log In",
                header: "Login",
                welcome_back: "Welcome back!",
                email: "Email",
                password: "Password",
                login: "Login",
                or: "or",
                no_account: "Don't have an account?",
                signup: "Sign Up",
                forgot_password: "Forgot your password?",
                reset_password: "Reset Password"
            },
            signup: {
                title: "Sign Up",
                questline_selection: {
                    header: "Let's begin by selecting a questline.",
                    text: "Your chosen path will guide the lessons you receive.",
                    coming_soon: "Coming Soon!"
                },
                email: {
                    header: "Let's get you signed up!",
                    text: "Next, we'll need an email.",
                    placeholder: "Enter your email address",
                    next: "Next",
                    or: "or"
                },
                details: {
                    header: "We need just a few more details.",
                    name: "Name",
                    name_placeholder: "Name (First Last)",
                    password: {
                        title: "Password",
                        placeholder: "Password",
                        verify: "Verify Password",
                        password_requirements:
                            "At least 8 characters, 1 number, 1 uppercase letter."
                    },
                    signup: {
                        header: "We're signing you up...",
                        text: "This should only take a moment..."
                    }
                },
                signin: {
                    header: "Hold on tight!",
                    text: "We're signing you in..."
                },
                subscription: {
                    maybe_later: "Maybe Later"
                },
                action: {
                    header: "We hope you learned something!",
                    sub: {
                        1: "Sign up to ",
                        2: "continue learning",
                        3: " and advance your CompTIA A+ journey today!"
                    },
                    button: "Start your free trial"
                }
            },
            next_lesson: "Next lesson",
            launch: "Launch",
            privacy: "Privacy Policy & Terms of Service",
            subscribe: "Subscribe for more",
            node_unavailable: "Node unavailable",
            signup_to_access: "Signup to access",
            start_node: "Start",
            recenter: "recenter"
        },
        end_card: {
            header: "Lesson completed:",
            progress_text: "Questline progress:",
            earned: "Earned",
            streak: "Streak",
            total: "Total",
            shop_ship: "Use these to buy ships from the ship shop!",
            return: "Return to Map"
        },
        streak: {
            header: "Streak",
            message: "Streaks are reset when you miss a day",
            days: "Days"
        },
        phaserfeedback: {
            title: "Feedback",
            header: "Lesson Feedback",
            paragraph:
                "We value your insights! Please share your thoughts on this lesson or specific slides.",
            share: "Share Your Thoughts:",
            textarea_placeholder: "What's on your mind?",
            rating_placeholder: "How would you rate your experience?",
            submit: "Send Feedback"
        },
        uifeedback: {
            title: "Feedback",
            header: "We'd Love to Hear From You!",
            paragraph:
                "Your input drives our innovation. Sharing your insights helps us to enhance Arkitect, making every \
                lesson more interactive and our interface more user-friendly. Let us know what you think!",
            share: "Share Your Thoughts:",
            textarea_placeholder: "What's on your mind?",
            submit: "Send Feedback"
        },
        friends: {
            title: "Friends",
            case_sensitive: "Usernames are case sensitive",
            friends_list: "Friends List",
            incoming_requests: "Incoming Requests",
            outgoing_requests: "Outgoing Requests",
            add_user: "Add User",
            remove_user: "Remove",
            accept_user: "Accept",
            reject_user: "Reject",
            cancel_request: "Cancel"
        },
        shop: {
            title: "Ship Shop",
            welcome: "Welcome to the Shop!",
            selected: "Selected",
            select: "Select",
            owned: "Owned",
            purchase: "Purchase",
            unavailable: "Not Enough"
        },
        patchnotes: {
            title: "Patch Notes",
            version: "Version",
            changes: [
                "✨ <b>Complete Design Overhaul</b><br>&nbsp;&nbsp;- We’ve refreshed the entire app with a new theme, updated colors, and an improved overall look and feel.",
                "⚙️ <b>Backend and Frontend Overhaul</b><br>&nbsp;&nbsp;- We’ve made major updates to both the backend and frontend—about half of the app’s core was rewritten to make everything run smoother and feel better to use.",
                "📚 <b>Introducing a New Lesson: Encryption</b><br>&nbsp;&nbsp;- A brand-new lesson on encryption is now available. This lesson features our updated lesson style, which aligns with the app’s new design.",
                "👋 <b>Guest Access Updated</b><br>&nbsp;&nbsp;-The available guest lesson has been updated to feature the new encryption content.",
                "🌐 <b>Web App Launch with PayPal Support</b><br>&nbsp;&nbsp;- We’re excited to announce the launch of our web app at <a href='https://learn.skillrocket.ca' target='_blank'>learn.skillrocket.ca</a>, which now supports PayPal for payments."
            ]
        },
        subscriptions: {
            early_access: "Early Access",
            unlimited: "Unlimited access",
            new_content: "New lessons every week",
            review: "Review with custom flashcards and exams",
            prep: "Prep for popular certifications",
            trial: "... and the first week is free",
            new: "(For new customers)",
            week: "1 week trial +",
            button: {
                already_subscribed: "Already Subscribed",
                subscribe: "Subscribe"
            },
            billing:
                "You won't be charged if you cancel before the end of the free trial period. After the trial, billing starts and recurs at the beginning of each billing period. You can cancel anytime in the",
            manage: "Manage Subscription",
            unavailable: {
                title: "Sorry!",
                message:
                    "Subscriptions are currently not available through the web. To subscribe, please visit your app store and download our app!"
            },
            policy: {
                text: "By subscribing, you agree to our",
                link: "Terms of Service and Privacy Policy"
            }
        },
        welcomemodal: {
            headers: [
                "Learn with ease",
                "Get tech certified today",
                "Affordable and portable"
            ],
            texts: [
                "Learn tech with easy to understand lessons, consistent quizzes and flashcards.",
                "We make getting tech certified & landing that job in the competitive workforce of today easy.",
                "No classrooms, no expensive books and courses. Accessible and fun lessons at your fingertips."
            ],
            slide1: {
                title: "Welcome to SkillRocket!",
                text: "Let's get started by selecting a career path."
            },
            slide2: {
                title: "Awesome, now let's pick a certification!",
                text: "Explore a free lesson from this professional certification before starting your 7-day trial!"
            },
            slide3: {
                title: "Let's get started!",
                text: "Choose a ship and launch into your first lesson!",
                sub: "Signup and unlock more ships as you complete lessons."
            }
        },
        dashboard: {
            title: "Settings",
            account: {
                title: "Account",
                userInfo: {
                    title: "User Info",
                    header: "Account",
                    account_info: "Account Information",
                    username: {
                        title: "Username",
                        header: "Username",
                        enter_username: "Please enter a new username below:",
                        username_input: {
                            status_message:
                                "Begin typing to check username availability.",
                            generate_username: "Generate Username"
                        }
                    },
                    email: {
                        title: "Email",
                        header: "Email",
                        enter_email: "Please enter a new email below:"
                    }
                },
                subscription: "Subscription",
                preferences: {
                    title: "Preferences",
                    header: "App Preferences",
                    sub_header: "Preferences",
                    language: "Language",
                    dark: "Dark Mode",
                    haptics: "Haptics",
                    audio: "Audio",
                    narration: "Narration"
                }
            },
            support: {
                title: "Support",
                support: "Support",
                contact: "Contact Us",
                in_touch: "Get in Touch",
                fill_form_request:
                    "Please fill out the form below to get in touch with us.",
                return_email: "Return Email",
                question: "What do you need help with?",
                response_placeholder: "Type your message here..."
            },
            login: {
                title: "Login",
                delAccount: {
                    title: "Delete Account",
                    header: "Account deletion",
                    notice: "Notice",
                    warning:
                        "Once your account is deleted, your account will be permanently removed from our system.",
                    enter_email: "Please enter your email below:",
                    delete: "Delete"
                },
                logout: "Logout"
            }
        }
    };
});
