<template>
    <div>
        <ion-header>
            <ion-toolbar>
                <ion-buttons slot="start">
                    <ion-back-button defaultHref="/">Back</ion-back-button>
                </ion-buttons>
                <ion-title>Subscription</ion-title>
            </ion-toolbar>
        </ion-header>
        <ion-router-outlet />
    </div>
</template>



<script lang="ts">
import audioMixin from '../../mixins/audioMixin';

export default {
    name: 'SubscriptionNav',
    mixins: [audioMixin]
}

</script>