<template>
    <ion-page>
        <ion-header>
            <ion-toolbar>
                <ion-buttons slot="start">
                    <ion-back-button defaultHref="/" :text="$t('nav.back')"></ion-back-button>
                </ion-buttons>
            </ion-toolbar>
        </ion-header>
        <ion-content v-if="uStore.loggedIn" class="ion-margin darkmode">
            <ion-refresher slot="fixed" @ionRefresh="handleRefresh($event)">
                <ion-refresher-content></ion-refresher-content>
            </ion-refresher>
            <h1 class="ion-padding">
                {{ $t('dashboard.title') }}
            </h1>
            <sub class="ion-margin"><b>{{ $t('dashboard.account.title') }}</b></sub>
            <ion-list class="ion-margin-bottom ion-margin-top">
                <ion-item button class="ion-margin-end" router-link="/dashboard/user/">
                    <GlobalIcon class="ion-margin-end" :size="'small'" :iconName="personOutline" />{{
                        $t('dashboard.account.userInfo.title') }}
                </ion-item>
                <ion-item button class="ion-margin-end" router-link="/dashboard/subscriptions">
                    <GlobalIcon class="ion-margin-end" :size="'small'" :iconName="walletOutline" />{{
                        $t('dashboard.account.subscription') }}
                </ion-item>
                <ion-item button class="ion-margin-end" router-link="/dashboard/userprefs">
                    <GlobalIcon class="ion-margin-end" :size="'small'" :iconName="settingsOutline" />{{
                        $t('dashboard.account.preferences.title') }}
                </ion-item>
            </ion-list>
            <sub class="ion-margin"><b>{{ $t('dashboard.support.title') }}</b></sub>
            <ion-list class="ion-margin-bottom ion-margin-top">
                <ion-item button class="ion-margin-end" router-link="/dashboard/support/contactus">
                    <GlobalIcon class="ion-margin-end" :size="'small'" :iconName="chatbubbleEllipsesOutline" />
                    {{ $t('dashboard.support.support') }}
                </ion-item>
            </ion-list>
            <sub class="ion-margin"><b>{{ $t('dashboard.login.title') }}</b></sub>
            <ion-list class="ion-margin-bottom ion-margin-top">
                <ion-item button class="ion-margin-end" router-link="/dashboard/login/deactivate">
                    <GlobalIcon class="ion-margin-end" :size="'small'" :iconName="lockClosedOutline" />{{
                        $t('dashboard.login.delAccount.title') }}
                </ion-item>
                <ion-item button class="ion-margin-end" @click="logout()">
                    <GlobalIcon class="ion-margin-end" :size="'small'" :iconName="logOutOutline" />{{
                        $t('dashboard.login.logout') }}
                </ion-item>
            </ion-list>
            <div v-if="uStore.user.is_staff">
                <sub class="ion-margin"><b>Developer</b></sub>
                <ion-list class="ion-margin-bottom ion-margin-top">
                    <ion-item button class="ion-margin-end" @click="resetCompletion()">
                        <GlobalIcon class="ion-margin-end" :size="'small'" :iconName="refreshOutline" />Reset Completion
                    </ion-item>
                </ion-list>
            </div>
        </ion-content>
    </ion-page>
</template>

<script>
import { useKeywordQuestionStore } from '@/store/keywordQuestionStore';
import { useNodeStore } from '@/store/nodeStore';
import { useUserStore } from '@/store/userStore'
import { logOutOutline, lockClosedOutline, fileTrayFullOutline, chatbubbleEllipsesOutline, personOutline, settingsOutline, refreshOutline, hammerOutline, walletOutline, filmOutline } from 'ionicons/icons';
import GlobalIcon from '~/components/GlobalIcon.vue';
import { orbitGuestNode } from "../../utils/latticeUtils";
import { checkRefreshExpiry } from "../utils/utils";

export default {
    components: {
        GlobalIcon,
    },
    setup() {
        const uStore = useUserStore()
        const nStore = useNodeStore()
        const kqStore = useKeywordQuestionStore()

        return {
            uStore,
            nStore,
            kqStore,
            logOutOutline,
            lockClosedOutline,
            fileTrayFullOutline,
            chatbubbleEllipsesOutline,
            personOutline,
            settingsOutline,
            refreshOutline,
            hammerOutline,
            walletOutline,
            filmOutline
        }
    },
    mounted() {
        checkRefreshExpiry();
    },
    methods: {
        async logout() {
            await this.uStore.logout();
            orbitGuestNode();
        },
        resetCompletion() {
            try {
                const body = {
                    "nodes": [],
                    "known_keywords": [],
                    "user": this.uStore.user.id
                }
                $fetch(this.$config.public.API_BASE_URL + 'completion/' + this.uStore.user.id + '/', {
                    method: 'PATCH',
                    headers: new Headers({
                        'Authorization': 'Bearer ' + this.uStore.accessToken,
                    }),
                    body: body
                }).then(res => {
                    console.log(`Completion table reset - user: ${this.uStore.user.id}\n${JSON.stringify(res)}`)
                })
            } catch (err) {
                console.log(err)
            }
        },
        handleRefresh(event) {
            (async () => {
                try {
                    await this.uStore.updateUserDetailsAndPrefs();
                    await this.nStore.getLatticeData(true);
                    await this.kqStore.fetchLatestKQs();
                    event.target.complete();
                    console.log('Refreshed!');
                } catch (error) {
                    console.error('Error during refresh:', error);
                }
            })();
        },
    }
}
</script>

<style>
ion-item {
    --detail-icon-opacity: 1;
    --detail-icon-font-size: 14px;
}
</style>