<template>
    <div>
        <div class="scrollable-list">
            <ion-list class="list-theme">
                <ion-item v-for="questline in questlines" :key="questline.name">
                    <div class="button-container">
                        <ion-button :disabled="!questline.available" @click="toggleWelcomeModal()">
                            <div class="button-content">
                                <span class="questline-name">{{ questline.name }}</span>
                                <div class="badges-container">
                                    <span :class="['skill-badge', getBadgeClass(questline.skill)]">
                                        Skill: {{ questline.skill }}
                                    </span>
                                    <span :class="['salary-badge', getBadgeClass(questline.skill)]">
                                        Salary: {{ questline.salary }}*
                                    </span>
                                </div>
                            </div>
                        </ion-button>
                    </div>
                </ion-item>
            </ion-list>
        </div>
    </div>
    <p class="salary-text"><span style="color:red">*</span> Salary figures displayed are approximate and intended for
        general information purposes only. Actual compensation may vary based on individual qualifications, experience,
        and specific job requirements.</p>
</template>


<script lang="ts">
export default {
    name: 'QuestlineSelection',
    emits: ['QuestlineSelected'],
    methods: {
        toggleWelcomeModal() {
            this.$emit('QuestlineSelected');
        },
        getBadgeClass(skill: string): string {
            switch (skill) {
                case 'Beginner':
                    return 'badge-entry';
                case 'Intermediate':
                    return 'badge-intermediate';
                case 'Expert':
                    return 'badge-expert';
                default:
                    return 'badge-default';
            }
        }
    },
    data() {
        return {
            questlines: [
                { name: 'A+', salary: "$60k", skill: 'Beginner', available: true },
                { name: 'Net+', salary: "$80k", skill: "Intermediate", available: false },
                { name: 'Sec+', salary: "$90k", skill: "Intermediate", available: false },
                { name: 'CASP+', salary: "$150k", skill: "Expert", available: false },
            ]
        }
    },
}
</script>

<style scoped>
.salary-text {
    font-size: 0.75rem;
    margin-top: 0.25rem;
    padding: .25rem;
    text-align: center;
}

.scrollable-list {
    max-height: 100%;
    overflow-y: scroll;
    margin-top: 1rem;
    position: relative;
}

.button-container {
    display: flex;
    justify-content: center;
    width: 100%;
    position: relative;
}

.button-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.skill-badge,
.salary-badge {
    display: inline-block;
    border-radius: 12px;
    padding: 0.05rem 0.3rem;
    margin: 0 0.5rem 0.5rem 0.5rem;
    color: white;
    font-size: 0.75rem;
    border: 1px solid rgba(0, 0, 0, 0.25);
}

.questline-name {
    text-align: center;
    position: relative;
    padding-top: 1rem;
    font-size: 1.5rem;
    font-weight: bold;
}

ion-list {
    padding-top: 1rem;
    padding-bottom: 1rem;
}

ion-button {
    font-size: 1.1rem;
    width: 100%;
    height: 4rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    position: relative;
    font-weight: bold;
    transition: background-color 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
}

ion-button:disabled {
    background-color: #d3d3d3;
    color: #808080;
    cursor: not-allowed;
}

.button-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}


.badge-entry {
    background-color: #4caf50;
}

.badge-intermediate {
    background-color: #2196f3;
}

.badge-expert {
    background-color: #ff0000;
}

.badge-default {
    background-color: #9e9e9e;
}

.list-theme {
    background: transparent;
}
</style>
