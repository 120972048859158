import { useUserStore } from "../store/userStore";
import { useNodeStore } from "../store/nodeStore";
import { useVngStore } from "../store/vngStore";
import { useModalStore } from "../store/modalStore";
import { gsap, MotionPathPlugin, CustomEase } from "gsap/all";
import { useNuxtApp } from "nuxt/app";
import { bucketBaseUrl } from "./latticeUtils";

export class UserShip {
    private ship: SVGGElement;
    private cockpit: SVGGElement;
    private cockpitImage: SVGImageElement;
    private shipContainer: SVGGElement;
    private flame: SVGImageElement;
    private flame2: SVGImageElement;
    private shadow: SVGRectElement;
    private dustClouds:
        | {
              dustCloudLeft: SVGImageElement;
              dustCloudRight: SVGImageElement;
          }
        | undefined;
    private dustCloudsPlayed: Boolean = false;
    private path: SVGPathElement;

    private orbitPath: SVGPathElement;
    private orbitTargetTween?: gsap.core.Tween;
    private wiggleTweens?: gsap.core.Tween[] = [];
    private moveToTween?: gsap.core.Tween;
    private moveToDuration = useUserStore().loggedIn ? 2.5 : 5;
    private rotateToTween?: gsap.core.Tween;
    private lastSelectedNode: number | undefined;
    private observer: IntersectionObserver | null = null;

    public vngStore = useVngStore();

    constructor() {
        gsap.registerPlugin(MotionPathPlugin);
        gsap.registerPlugin(CustomEase);
        gsap.ticker.fps(60);
    }

    public async getShip(): Promise<SVGGElement> {
        if (!this.ship) {
            await this.initializeShip();
        }
        return this.ship;
    }

    public get x(): number {
        return this.ship.transform.baseVal.consolidate()?.matrix.e ?? 0;
    }

    public get y(): number {
        return this.ship.transform.baseVal.consolidate()?.matrix.f ?? 0;
    }

    public get angle(): number {
        const matrix = this.ship.transform.baseVal.consolidate()?.matrix;
        if (!matrix) return 0;
        return ((Math.atan2(matrix.b, matrix.a) * 180) / Math.PI) % 360;
    }

    public async setScale(scale: number): Promise<void> {
        await gsap.to(this.ship, {
            scale,
            duration: 0.5,
            overwrite: true
        });
    }

    public async setFlameVisibility(visible: boolean): Promise<void> {
        if (visible) {
            this.flame.style.display = "block"; // small flame
            this.flame2.style.display = "block"; // big flame
            gsap.fromTo(
                this.flame,
                { opacity: 0 },
                {
                    opacity: 1,
                    duration: 0.3,
                    ease: "power4.in",
                    onStart: () => {
                        this.wiggleTweens?.[0].play();
                    }
                }
            );
            gsap.fromTo(
                this.flame2,
                { opacity: 0 },
                {
                    opacity: 1,
                    duration: 0.3,
                    ease: "power4.in",
                    onStart: () => {
                        this.wiggleTweens?.[1].play();
                    }
                }
            );
        } else {
            gsap.fromTo(
                this.flame2,
                { opacity: 1 },
                {
                    opacity: 0,
                    duration: 0.3,
                    ease: "power4.out",
                    onComplete: () => {
                        this.flame2.style.display = "none";
                        this.wiggleTweens?.[1].pause();
                    }
                }
            );
        }
    }

    public setOrReturnShipTexture(
        setTexture: boolean = false,
        overrideID?: number
    ): string {
        const uStore = useUserStore();
        const shipQuery = useNuxtApp().$router.currentRoute.value.query.ship;

        let shipID = overrideID ?? 6;
        if (!uStore.loggedIn && typeof shipQuery === "string") {
            const parsedShipID = parseInt(shipQuery, 10);
            shipID = isNaN(parsedShipID) ? 6 : parsedShipID;
        }

        const selectedShip = uStore.userPersonalization?.selected_ship;
        let userShipTexturePath: string;

        if (selectedShip?.blob_link) {
            userShipTexturePath = `${bucketBaseUrl}/${selectedShip.blob_link}`;
        } else {
            userShipTexturePath = `${bucketBaseUrl}/General/Assets/Ships/${shipID}.png`;
        }

        if (setTexture) {
            // Ensure the transform-origin is set to center
            gsap.fromTo(
                this.cockpitImage,
                { opacity: 1 },
                {
                    opacity: 0,
                    duration: 0.5,
                    onComplete: () => {
                        this.cockpitImage.setAttribute(
                            "href",
                            userShipTexturePath
                        );
                        gsap.to(this.cockpitImage, {
                            opacity: 1,
                            duration: 0.5
                        });
                    }
                }
            );
        }

        return userShipTexturePath;
    }

    private async initializeShip(): Promise<void> {
        if (!this.vngStore.getSvgLatticeElement) {
            console.warn(
                "Svg Lattice Element is not initialized. Refetching..."
            );
        }

        if (!this.vngStore.getVngComp) {
            console.warn("Missing VngComp, Returning");
            return;
        }

        this.ship = document.createElementNS("http://www.w3.org/2000/svg", "g");
        this.ship.setAttribute("id", "ship");
        this.ship.classList.add("ship");

        this.shipContainer = document.createElementNS(
            "http://www.w3.org/2000/svg",
            "g"
        );
        this.shipContainer.setAttribute("id", "shipContainer");
        this.ship.appendChild(this.shipContainer);

        this.cockpit = await this.createCockpit();
        this.shipContainer.appendChild(this.cockpit);

        this.flame = await this.createFlame();
        this.flame2 = await this.createFlame(true);
        this.cockpit.appendChild(this.flame2);
        this.cockpit.appendChild(this.flame);

        if (useUserStore().loggedIn) {
            // Random Spawn
            const canvasX = this.vngStore.getVngComp?.getSizes().viewBox.x;
            const canvasY = this.vngStore.getVngComp?.getSizes().viewBox.y;
            const randomX = Math.random() * canvasX;
            const randomY = Math.random() * canvasY;
            gsap.set(this.ship, { x: randomX, y: randomY, scale: 0.5 });
        } else {
            // Guest Spawn
            const height = this.vngStore.getVngComp.getSizes().height;
            const yPosition = height * 0.25;
            gsap.set(this.ship, { x: 15, y: yPosition }); // Adding 10 because idk why
        }

        gsap.set(this.cockpit, { transformOrigin: "50% 50%" });

        this.shadow = await this.createShadow();
        this.vngStore.getSvgLatticeElement.appendChild(this.shadow);
        this.vngStore.getSvgLatticeElement.appendChild(this.ship);

        if (!useUserStore().loggedIn) {
            this.rotateTo(-90);
            this.setFlameVisibility(false);
            this.dustClouds = await this.createDustClouds();
            this.vngStore.getSvgLatticeElement.appendChild(
                this.dustClouds.dustCloudLeft
            );
            this.vngStore.getSvgLatticeElement.appendChild(
                this.dustClouds.dustCloudRight
            );
        }
    }

    public async moveTo(
        x: number,
        y: number,
        orbitAngleDegrees?: number
    ): Promise<void> {
        if (this.moveToTween || this.orbitTargetTween) {
            this.moveToTween?.kill();
            this.moveToTween = undefined;
            this.orbitTargetTween?.kill();
            this.orbitTargetTween = undefined;
            this.removePath();
            await this.setScale(!useUserStore().loggedIn ? 1 : 0.5);
        }

        await this.createBezierPathTo(x, y, orbitAngleDegrees ?? this.angle);
        if (this.path) {
            // Use the Bézier path for animation
            this.moveToTween = gsap.to(this.ship, {
                duration: this.moveToDuration,
                ease: useUserStore().loggedIn
                    ? CustomEase.create(
                          "custom",
                          "M0,0 C0.3,0.4 0.193,-0.031 0.514,0.43 0.641,0.614 0.733,0.923 1,1"
                      )
                    : CustomEase.create(
                          "custom",
                          "M0,0 C0.123,0.378 0.193,-0.031 0.514,0.43 0.641,0.614 0.733,0.923 1,1"
                      ),
                motionPath: {
                    path: this.path,
                    align: this.path,
                    alignOrigin: [0.5, 0.5],
                    autoRotate: true
                },
                overwrite: true,
                onStart: () => {
                    this.setFlameVisibility(true);
                },
                onComplete: () => {
                    this.setFlameVisibility(false);
                },
                onUpdate: () => {
                    const matrix =
                        this.ship.transform.baseVal.consolidate()?.matrix;
                    if (matrix) {
                        this.updateShadowPosition(matrix.e, matrix.f);
                    }
                }
            });
        }

        await this.moveToTween;
        this.removePath();
        this.orbitTargetTween = undefined;
    }

    public async rotateTo(angle: number): Promise<void> {
        if (this.rotateToTween) {
            this.rotateToTween.kill();
            this.rotateToTween = undefined;
        }
        let difference = angle - this.angle;
        if (difference > 180) {
            difference -= 360;
        } else if (difference < -180) {
            difference += 360;
        }
        this.rotateToTween = gsap.to(this.ship, {
            rotation: `+=${difference}`,
            transformOrigin: "50% 50%",
            smoothOrigin: false,
            duration: 0.25,
            overwrite: true,
            onUpdate: () => {
                const matrix =
                    this.ship.transform.baseVal.consolidate()?.matrix;
                if (matrix) {
                    this.updateShadowPosition(matrix.e, matrix.f);
                }
            }
        });
        await this.rotateToTween;
    }

    public startOrbitingTarget(
        x: number,
        y: number,
        nodeId: number
    ): Promise<void> {
        return new Promise(async (resolve) => {
            if (nodeId == this.lastSelectedNode) {
                resolve();
                return;
            } else {
                this.lastSelectedNode = nodeId;
            }

            const orbitMultiplier = 1.25;
            const orbitRadius =
                useNodeStore().availableNodes[nodeId].size * orbitMultiplier;

            const angleToNode = Math.atan2(this.y - y, this.x - x);
            const orbitStartX = x + orbitRadius * Math.cos(angleToNode);
            const orbitStartY = y + orbitRadius * Math.sin(angleToNode);
            const orbitAngleRadians = angleToNode + Math.PI / 2;
            const orbitAngleDegrees = (orbitAngleRadians * 180) / Math.PI;
            await this.moveTo(orbitStartX, orbitStartY, orbitAngleDegrees);

            if (this.orbitTargetTween) {
                this.orbitTargetTween.kill();
                this.orbitTargetTween = undefined;
                this.stopVisibilityCheck();
            }

            this.orbitPath = document.createElementNS(
                "http://www.w3.org/2000/svg",
                "path"
            );
            const orbitPathData = `
                M ${orbitStartX} ${orbitStartY}
                A ${orbitRadius} ${orbitRadius} 0 1 0 ${x + orbitRadius * Math.cos(angleToNode + Math.PI)} ${y + orbitRadius * Math.sin(angleToNode + Math.PI)}
                A ${orbitRadius} ${orbitRadius} 0 1 0 ${orbitStartX} ${orbitStartY}
            `;
            this.orbitPath.setAttribute("d", orbitPathData.trim());
            this.orbitPath.setAttribute("fill", "none");
            this.orbitPath.setAttribute("stroke", "none");
            this.vngStore.getSvgLatticeElement.appendChild(this.orbitPath);

            this.orbitTargetTween = gsap.to(this.ship, {
                duration: orbitRadius * 0.25,
                ease: "none",
                motionPath: {
                    path: this.orbitPath,
                    align: "self",
                    alignOrigin: [0.5, 0.5],
                    autoRotate: true
                },
                overwrite: true,
                repeat: -1,
                onStart: () => {
                    resolve();
                },
                onUpdate: () => {
                    const matrix =
                        this.ship.transform.baseVal.consolidate()?.matrix;
                    if (matrix) {
                        this.updateShadowPosition(matrix.e, matrix.f);
                    }
                }
            });

            if (!useUserStore().loggedIn) {
                this.startVisibilityCheck();
            }
        });
    }

    private async createBezierPathTo(
        x: number,
        y: number,
        orbitAngleDegrees: number
    ): Promise<{
        controlPoint1: { x: number; y: number };
        controlPoint2: { x: number; y: number };
    }> {
        return new Promise((resolve) => {
            const path = document.createElementNS(
                "http://www.w3.org/2000/svg",
                "path"
            );
            const pathId = `path-${Date.now()}`;
            path.setAttribute("id", pathId);

            const shipAngleRadians = (this.angle * Math.PI) / 180;
            const controlDist1 = 100;
            const controlPoint1 = {
                x: this.x + Math.cos(shipAngleRadians) * controlDist1,
                y: this.y + Math.sin(shipAngleRadians) * controlDist1
            };

            const adjustedOrbitAngleDegrees = (orbitAngleDegrees + 180) % 360;
            const orbitAngleRadians =
                (adjustedOrbitAngleDegrees * Math.PI) / 180;
            const controlDist2 = 100;
            const controlPoint2 = {
                x: x - Math.cos(orbitAngleRadians) * controlDist2,
                y: y - Math.sin(orbitAngleRadians) * controlDist2
            };
            const pathData = `
                M ${this.x} ${this.y}
                C ${controlPoint1.x} ${controlPoint1.y},
                  ${controlPoint2.x} ${controlPoint2.y},
                  ${x} ${y}
            `;
            path.setAttribute("d", pathData.trim());
            path.setAttribute("stroke", "none");
            path.setAttribute("fill", "none");
            this.vngStore.getSvgLatticeElement.appendChild(path);
            this.path = path;

            resolve({ controlPoint1, controlPoint2 });
        });
    }

    public removePath(): void {
        if (this.path) {
            this.path.remove();
        }
    }

    public animateDustClouds(): void {
        if (this.dustClouds && !this.dustCloudsPlayed) {
            gsap.fromTo(
                this.dustClouds.dustCloudLeft,
                {
                    autoAlpha: 1
                },
                {
                    autoAlpha: 0,
                    x: "-=50",
                    duration: 1,
                    ease: "sine.inOut",
                    repeat: 0
                }
            );
            gsap.fromTo(
                this.dustClouds.dustCloudRight,
                {
                    autoAlpha: 1
                },
                {
                    autoAlpha: 0,
                    x: "+=50",
                    duration: 1,
                    ease: "sine.inOut",
                    repeat: 0
                }
            );
        }
        this.dustCloudsPlayed = true;
    }

    private stopVisibilityCheck() {
        if (this.observer) {
            this.observer.disconnect();
            this.observer = null;
        }
    }

    private startVisibilityCheck() {
        const mStore = useModalStore();
        if (this.observer) return;

        this.observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (!mStore.isNodeOpen) {
                        this.handleVisibilityChange(entry.isIntersecting);
                    }
                });
            },
            {
                root: null,
                threshold: 0
            }
        );

        this.observer.observe(this.ship);
    }

    private handleVisibilityChange(isVisible: boolean) {
        if (this.orbitTargetTween) {
            console.log("Visibility changed to", isVisible);
            this.orbitTargetTween.timeScale(isVisible ? 1 : 8);
        }
    }

    private async createCockpit(): Promise<SVGGElement> {
        const cockpit = document.createElementNS(
            "http://www.w3.org/2000/svg",
            "g"
        );
        this.cockpitImage = document.createElementNS(
            "http://www.w3.org/2000/svg",
            "image"
        );
        const imageSrc = await this.setOrReturnShipTexture(false);
        this.cockpitImage.setAttribute("x", "-40");
        this.cockpitImage.setAttribute("y", "-40");
        this.cockpitImage.setAttribute("width", "80");
        this.cockpitImage.setAttribute("height", "80");
        this.cockpitImage.setAttribute("href", imageSrc);
        cockpit.appendChild(this.cockpitImage);
        return cockpit;
    }

    private async createShadow(): Promise<SVGRectElement> {
        const shadow = document.createElementNS(
            "http://www.w3.org/2000/svg",
            "rect"
        );
        shadow.setAttribute("x", "0");
        shadow.setAttribute("y", "0");
        shadow.setAttribute("width", "35");
        shadow.setAttribute("height", "35");
        shadow.setAttribute("fill", "url(#ship-shadow-gradient)");
        return shadow;
    }

    private async createFlame(differentEase = false): Promise<SVGImageElement> {
        const flame = document.createElementNS(
            "http://www.w3.org/2000/svg",
            "image"
        );
        flame.setAttribute("x", `${differentEase ? -75 : -57}`);
        flame.setAttribute("y", `${differentEase ? -20 : -10}`);
        flame.setAttribute("width", `${differentEase ? 40 : 20}`);
        flame.setAttribute("height", `${differentEase ? 40 : 20}`);
        flame.setAttribute("href", `/images/shipFlame.png`);
        gsap.set(flame, { opacity: 0 });

        const tween = gsap.fromTo(
            flame,
            {
                rotation: differentEase ? -10 : 10
            },
            {
                duration: `${differentEase ? 0.25 : 0.25}`,
                rotation: differentEase ? 10 : -10,
                yoyo: true,
                repeat: -1,
                transformOrigin: `${differentEase ? "30px 20px" : "15px 10px"}`,
                smoothOrigin: false,
                ease: "circ.inOut"
            }
        );
        this.wiggleTweens?.push(tween);

        return flame;
    }

    private async createDustClouds(): Promise<{
        dustCloudLeft: SVGImageElement;
        dustCloudRight: SVGImageElement;
    }> {
        const dustCloudLeft = document.createElementNS(
            "http://www.w3.org/2000/svg",
            "image"
        );

        dustCloudLeft.setAttribute("width", "60");
        dustCloudLeft.setAttribute("height", "60");
        dustCloudLeft.setAttribute("href", `/images/dustcloud.png`);
        dustCloudLeft.style.visibility = "none";
        dustCloudLeft.style.opacity = "0";
        gsap.set(dustCloudLeft, {
            x: this.x - 70,
            y: this.y + 30,
            rotate: 270
        });

        const dustCloudRight = document.createElementNS(
            "http://www.w3.org/2000/svg",
            "image"
        );

        dustCloudRight.setAttribute("width", "60");
        dustCloudRight.setAttribute("height", "60");
        dustCloudRight.setAttribute("href", `/images/dustcloud.png`);
        dustCloudRight.style.visibility = "none";
        dustCloudRight.style.opacity = "0";
        gsap.set(dustCloudRight, {
            x: this.x - 30,
            y: this.y + 30,
            rotate: 270
        });

        return { dustCloudLeft, dustCloudRight };
    }

    private async createBox(shipContainer: any): Promise<SVGRectElement> {
        const box = document.createElementNS(
            "http://www.w3.org/2000/svg",
            "rect"
        );
        const bbox = shipContainer.getBBox();
        box.setAttribute("x", bbox.x.toString());
        box.setAttribute("y", bbox.y.toString());
        box.setAttribute("width", bbox.width.toString());
        box.setAttribute("height", bbox.height.toString());
        box.setAttribute("fill", "none");
        box.setAttribute("stroke", "red");
        box.setAttribute("stroke-width", "1.5");
        return box;
    }

    private updateShadowPosition(x: number, y: number): void {
        gsap.set(this.shadow, {
            x: x - 30,
            y: y
        });
    }
}
