<template>
    <ion-page>
        <ion-header>
            <ion-toolbar>
                <ion-buttons slot="start">
                    <ion-back-button defaultHref="/" :text="$t('nav.back')"></ion-back-button>
                </ion-buttons>
            </ion-toolbar>
        </ion-header>
        <ion-content class="ion-margin">
            <h1 class="ion-padding">
                {{ $t('dashboard.account.userInfo.header') }}
            </h1>
            <sub class="ion-margin">{{ $t('dashboard.account.userInfo.account_info') }}</sub>
            <ion-list class="ion-margin-bottom ion-margin-top">
                <ion-item button class="ion-margin-end" router-link="/dashboard/user/username">
                    {{ $t('dashboard.account.userInfo.username.title') }}
                </ion-item>
                <ion-item button class="ion-margin-end" router-link="/dashboard/user/email">
                    {{ $t('dashboard.account.userInfo.email.title') }}
                </ion-item>
            </ion-list>
        </ion-content>
    </ion-page>
</template>

<style scoped>
ion-item {
    --detail-icon-opacity: 1;
    --detail-icon-font-size: 14px;
}
</style>