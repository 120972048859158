<template>
    <div class="div-style">
        <div class="header-content center-text">
            <ion-img class="image" :src="cardImage"></ion-img>
            <h1><b>{{ header }}</b></h1>
        </div>
        <p>{{ text }}</p>
    </div>
</template>

<script lang="ts">
import { useModalStore } from '../../store/modalStore';
import GlobalIcon from '../../components/GlobalIcon.vue';
import { rocketOutline } from 'ionicons/icons';
import { useGuestStore } from '../../store/guestStore';

export default {
    components: {
        GlobalIcon
    },
    setup() {
        return {
            rocketOutline
        };
    },
    props: {
        header: {
            type: String,
            required: true
        },
        text: {
            type: String,
            required: true
        },
        backgroundColor: {
            type: String,
            default: 'lightblue'
        },
        cardImage: {
            type: String,
            default: ''
        }
    },
    mounted() {
        this.$el.style.backgroundColor = this.backgroundColor;
    },
    methods: {
        signup() {
            const mStore = useModalStore();
            useGuestStore().submitActivity("signup_opened", 'From WelcomeModal');
            mStore.toggleWelcomeModal(false);
            mStore.toggleSignupModal(true);
        }
    }
}
</script>

<style scoped>
.div-style {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: .5rem;
    padding-bottom: 1.5rem;
}

.header-content {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: .25rem;
    margin-bottom: .25rem;
}

ion-img.image {
    width: 3rem;
    height: auto;
    object-fit: cover;
}

h1 {
    margin: 0;
    font-size: 1.1em;
}

p {
    font-size: 1em;
    text-align: center;
}

.signup {
    margin-top: 10px;
    width: 90%;
}

.swiper-viewport {
    background-color: transparent;
}
</style>
