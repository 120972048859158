<template>
    <ion-page>
        <ion-header>
            <ion-toolbar>
                <ion-buttons slot="start">
                    <ion-back-button defaultHref="/" :text="$t('nav.back')"></ion-back-button>
                </ion-buttons>
            </ion-toolbar>
        </ion-header>
        <ion-content class="ion-margin">
            <h1 class="ion-padding">
                {{ $t('dashboard.account.preferences.header') }}
            </h1>
            <sub class="ion-padding">{{ $t('dashboard.account.preferences.sub_header') }}</sub>
            <ion-list class="ion-margin-bottom ion-margin-top">
                <ion-item class="ion-margin-end">
                    <ion-select :label="$t('dashboard.account.preferences.language')" :placeholder="userLanguage"
                        @ionChange="languageSelected($event)">
                        <div v-for="locale in $i18n.locales" :key="locale.iso" class="language-list">
                            <ion-select-option>{{ locale.name
                                }}</ion-select-option>
                        </div>
                    </ion-select>
                </ion-item>
                <ion-item class="ion-margin-end">
                    <ion-toggle v-model="userPrefs.dark_mode" @ionChange="savePrefsUser()">{{
                        $t('dashboard.account.preferences.dark') }}</ion-toggle>
                </ion-item>
                <ion-item class="ion-margin-end">
                    <ion-toggle v-model="userPrefs.haptics" @ionChange="savePrefsUser()">{{
                        $t('dashboard.account.preferences.haptics') }}</ion-toggle>
                </ion-item>
                <ion-item class="ion-margin-end">
                    <ion-toggle v-model="userPrefs.sound" @ionChange="savePrefsUser()">{{
                        $t('dashboard.account.preferences.audio') }}</ion-toggle>
                </ion-item>
                <ion-item class="ion-margin-end">
                    <ion-toggle v-model="userPrefs.narration" @ionChange="savePrefsUser()">{{
                        $t('dashboard.account.preferences.narration') }}</ion-toggle>
                </ion-item>
            </ion-list>
        </ion-content>
    </ion-page>
</template>

<script>
import { useUserStore } from '@/store/userStore'
import CountryDropBox from '~/components/CountryDropBox.vue'

const uStore = useUserStore();

export default {
    components: {
        CountryDropBox
    },
    data() {
        return {
            userPrefs: {
                dark_mode: false,
                haptics: false,
                sound: false,
                narration: false
            }
        }
    },
    created() {
        if (uStore.user) {
            this.userPrefs = { ...this.userPrefs, ...uStore.userPrefs };
        }
    },
    methods: {
        languageSelected(event) {
            let locale = this.$i18n.locales.find((locale) => {
                return locale.name === event.detail.value
            }).code;
            uStore.updateUser({ language: locale });
            uStore.setUserLanguage(locale);
        },
        savePrefsUser() {
            console.log("saving prefs")
            uStore.updatePrefsUser(this.userPrefs);
        },
        toggleDarkMode() {
            if (this.userPrefs.dark_mode) {
                console.log("dark")
                document.body.classList.toggle('dark', true)
            } else {
                console.log("light")
                document.body.classList.toggle('dark', false)
            }
        }
    },
    watch: {
        userPrefs: {
            deep: true,
            immediate: true,
            handler: function (prefs) {
                this.toggleDarkMode()
            },
        }
    },
    computed: {
        userLanguage() {
            let lang = uStore.user?.language || 'en';
            return this.$i18n.locales.find((locale) => {
                return locale.code === lang
            }).name
        }
    }
}
</script>


<style scoped>
ion-item {
    --detail-icon-opacity: 1;
    --detail-icon-font-size: 14px;
}

ion-toggle {
    --track-background: #ddd;
    --track-background-checked: #ddd;

    --handle-background: #eb7769;
    --handle-background-checked: #95c34e;

    --handle-width: 22px;
    --handle-height: 22px;
    --handle-max-height: auto;
    --handle-spacing: 6px;

    --handle-border-radius: 4px;
    --handle-box-shadow: none;
}

ion-toggle::part(track) {
    height: 8px;
    width: 60px;
    overflow: visible;
}
</style>