<template>
    <button class="button" :disabled="disabled" :class="{ 'disabled': disabled }">
        <Vue3Lottie v-if="animationData" class="image-style" :animationData="animationData"></Vue3Lottie>
        <img v-if="src" :src="src" class="image-style"></img>
        <span class="button-text">{{ buttonText }}</span>
        <span v-if="disabled" class="coming-soon-badge">{{
            $t('home.signup.questline_selection.coming_soon') }}</span>
    </button>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { Vue3Lottie } from "vue3-lottie";

export default defineComponent({
    name: "CareerButton",
    components: {
        Vue3Lottie
    },
    props: {
        buttonText: {
            type: String, required: true
        },
        animationData: {
            type: Object, required: false
        },
        src: {
            type: String, default: '', required: false
        },
        disabled: {
            type: Boolean, default: false
        },
    },
});
</script>

<style scoped>
.button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 15rem;
    background-color: var(--ion-color-primary);
    color: var(--ion-color-primary-contrast);
    border-radius: 16px;
    font-size: 1rem;
    font-weight: bold;
    padding: 0.25rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease;
}

.button:hover:not(:disabled) {
    background-color: var(--ion-color-primary-shade);
}

.button.disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.button-text {
    padding-top: 1rem;
    text-align: center;
    font-size: 1.5rem;
}

.image-style {
    padding: 0.25rem;
    width: 5rem;
    height: 5rem;
}

.coming-soon-badge {
    background-color: #ff6347;
    color: white;
    font-size: 0.8rem;
    padding: 0.2rem 0.5rem;
    border-radius: 0.3rem;
    margin-top: 0.25rem;
    text-transform: uppercase;
}

@media (max-height: 900px) {
    .button {
        height: 12rem;
    }

    .button-text {
        font-size: 1.25rem;
    }
}

@media (min-width: 900px) {
    .button {
        height: 25rem;
    }

    .button-text {
        font-size: 1.5rem;
    }
}
</style>
