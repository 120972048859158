<template>
    <div class="ion-padding">
        <h1>Build Info</h1>
        <div class="ion-padding-start">
            <p>Arkitect Version {{ version }}</p>
            <p>Nuxt Version {{ nuxtVersion }}</p>
            <p>Capacitor Version {{ capacitorVersion }}</p>
            <p>Ionic Version {{ ionicVersion }}</p>
            <p>Phaser Version {{ phaserVersion }}</p>
            <p>D3 Version {{ d3Version }}</p>
            <p>VNG Version {{ vngVersion }}</p>
        </div>
        <img src="~/assets/logos/logo-dark.png" alt="Arkitect Logo" />
    </div>
</template>

<script>
import pkg from '~/package.json'
export default {
    data() {
        return {
            version: pkg.version,
            nuxtVersion: pkg.devDependencies['nuxt'],
            capacitorVersion: pkg.dependencies['@capacitor/core'],
            ionicVersion: pkg.dependencies['@nuxtjs/ionic'],
            phaserVersion: pkg.dependencies['phaser'],
            d3Version: pkg.dependencies['d3-force'],
            vngVersion: pkg.dependencies['v-network-graph']
        };
    }
};
</script>

<style scoped>
h1 {
    color: #35495e;
}

p {
    color: #35495e;
}

img {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100px;
    height: auto;
}
</style>