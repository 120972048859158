<template>
    <ion-page>
        <ion-header>
            <ion-toolbar>
                <ion-title class="ion-text-center">Email Verification</ion-title>
            </ion-toolbar>
        </ion-header>
        <ion-content class="ion-padding">
            <div class="validation-container">
                <ion-icon name="checkmark-circle" size="large" class="success-icon"></ion-icon>
                <h1 class="validation-heading">Email Verification Successful</h1>
                <p class="validation-text">
                    Your email address has been successfully verified. You can now return to the app and log in.
                </p>
                <!-- <ion-button expand="block" fill="solid" color="primary" @click="goBackToApp">Return to App</ion-button> -->
            </div>
        </ion-content>
    </ion-page>
</template>

<script>
export default {
    methods: {
        goBackToApp() {
        }
    }
};
</script>

<style scoped>
.validation-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 25%;
}

.success-icon {
    font-size: 48px;
    color: var(--ion-color-success);
    margin-bottom: 1em;
}

.validation-heading {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 0.5em;
}

.validation-text {
    font-size: 16px;
    text-align: center;
    margin-bottom: 2em;
}

ion-button {
    --background: var(--ion-color-primary);
    --border-radius: 10px;
    --box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}
</style>
