export default defineI18nLocale(async (locale) => {
    return {
        nav: {
            back: "Retour",
            save: "Enregistrer",
            submit: "Soumettre"
        },
        home: {
            login: {
                title: "Connexion",
                header: "Connexion",
                welcome_back: "Bienvenue de retour!",
                email: "Email",
                password: "Mot de passe",
                login: "Connexion",
                or: "ou",
                no_account: "Vous n'avez pas de compte?",
                signup: "S'inscrire",
                forgot_password: "Mot de passe oublié?",
                reset_password: "Réinitialiser le mot de passe"
            },
            signup: {
                title: "Inscription",
                questline_selection: {
                    header: "Commençons par choisir une ligne de quête.",
                    text: "Votre chemin choisi guidera les leçons que vous recevrez.",
                    coming_soon: "Bientôt disponible!"
                },
                email: {
                    header: "Inscrivons-vous !",
                    text: "Ensuite, nous aurons besoin d'un e-mail.",
                    placeholder: "E-mail",
                    next: "Suivant",
                    or: "ou"
                },
                details: {
                    header: "Nous avons juste besoin de quelques détails supplémentaires.",
                    name: "Nom",
                    name_placeholder: "Nom (Prénom Nom)",
                    password: {
                        title: "Mot de passe",
                        placeholder: "Mot de passe",
                        verify: "Vérifier le mot de passe",
                        password_requirements:
                            "Au moins 8 caractères, 1 chiffre, 1 lettre majuscule."
                    },
                    signup: {
                        header: "Nous vous inscrivons...",
                        text: "Cela ne devrait prendre qu'un instant..."
                    }
                },
                signin: {
                    header: "Tenez bon !",
                    text: "Nous sommes en train de vous connecter..."
                },
                subscription: {
                    maybe_later: "Peut-être plus tard"
                },
                action: {
                    header: "Nous espérons que vous avez appris quelque chose!",
                    sub: {
                        1: "Inscrivez-vous pour ",
                        2: "continuer à apprendre",
                        3: " et avancer dans votre parcours CompTIA A+ dès aujourd'hui!"
                    },
                    button: "Commencez votre essai gratuit"
                }
            },
            next_lesson: "Leçon suivante",
            launch: "Lancer!",
            privacy: "Politique de confidentialité et Conditions de service",
            subscribe: "S'abonner pour plus",
            node_unavailable: "Nœud indisponible",
            signup_to_access: "Inscrivez-vous pour accéder",
            start_node: "Commencer",
            recenter: "recentrer"
        },
        end_card: {
            header: "Leçon terminée :",
            progress_text: "Progression de la quête :",
            earned: "Gagné",
            streak: "Série",
            total: "Total",
            shop_ship:
                "Utilisez-les pour acheter des navires dans la boutique de navires !",
            return: "Retour à la carte"
        },
        streak: {
            header: "Série",
            message:
                "Les séries sont réinitialisées lorsque vous manquez une journée",
            days: "Jours"
        },
        phaserfeedback: {
            title: "Feedback",
            header: "Feedback sur la Leçon",
            paragraph:
                "Nous valorisons vos avis! Veuillez partager vos réflexions sur cette leçon ou des diapositives spécifiques.",
            share: "Partagez vos pensées:",
            textarea_placeholder: "Qu'est-ce qui vous préoccupe?",
            rating_placeholder: "Comment évalueriez-vous votre expérience?",
            submit: "Envoyer un feedback"
        },
        uifeedback: {
            title: "Feedback",
            header: "Nous aimons entendre vos avis!",
            paragraph:
                "Votre retour nous inspire notre innovation. Partager vos idées \
                nous aide à améliorer Arkitect, rendant chaque leçon plus interactive \
                et notre interface plus conviviale. Dites-nous ce que vous pensez!",
            share: "Partagez vos pensées:",
            textarea_placeholder: "Qu'est-ce qui vous préoccupe?",
            submit: "Envoyer un feedback"
        },
        friends: {
            title: "Amis",
            case_sensitive: "Les noms d'utilisateur sont sensibles à la casse",
            friends_list: "Liste des Amis",
            incoming_requests: "Demandes Entrantes",
            outgoing_requests: "Demandes Sortantes",
            add_user: "Ajouter un Utilisateur",
            remove_user: "Supprimer",
            accept_user: "Accepter",
            reject_user: "Rejeter",
            cancel_request: "Annuler"
        },
        shop: {
            title: "Boutique de Vaisseaux",
            welcome: "Bienvenue dans la Boutique!",
            selected: "Sélectionné",
            select: "Sélectionner",
            owned: "Possédé",
            purchase: "Acheter",
            unavailable: "Pas Assez"
        },
        patchnotes: {
            title: "Notes de Mise à Jour",
            version: "Version",
            changes: [
                "✨ <b>Refonte Complète du Design</b><br>&nbsp;&nbsp;- Nous avons entièrement repensé l'application avec un nouveau thème, des couleurs mises à jour et une apparence générale améliorée.",
                "⚙️ <b>Mise à Jour Backend et Frontend</b><br>&nbsp;&nbsp;- Des mises à jour majeures ont été apportées au backend et au frontend—environ 50 % du noyau de l'application a été réécrit pour améliorer les performances et l'expérience utilisateur.",
                "📚 <b>Introduction d'une Nouvelle Leçon : Chiffrement</b><br>&nbsp;&nbsp;- Une toute nouvelle leçon sur le chiffrement est désormais disponible. Cette leçon utilise notre style de leçon mis à jour, qui correspond au nouveau design de l'application.",
                "👋 <b>Accès Invité Mis à Jour</b><br>&nbsp;&nbsp;- La leçon disponible pour les invités a été mise à jour pour inclure le nouveau contenu sur le chiffrement.",
                "🌐 <b>Lancement de l'Application Web avec Support PayPal</b><br>&nbsp;&nbsp;- Nous sommes ravis d'annoncer le lancement de notre application web à l'adresse <a href='https://learn.skillrocket.ca' target='_blank'>learn.skillrocket.ca</a>, qui prend désormais en charge les paiements PayPal."
            ]
        },
        subscriptions: {
            early_access: "Accès anticipé",
            unlimited: "Accès illimité",
            new_content: "Nouveaux cours chaque semaine",
            review: "Révision avec des cartes mémoire et des examens personnalisés",
            prep: "Préparation pour les certifications populaires",
            trial: "... et la première semaine est gratuite",
            new: "(Pour les nouveaux clients)",
            week: "Essai de 1 semaine +",
            button: {
                already_subscribed: "Déjà abonné",
                subscribe: "S'abonner"
            },
            billing:
                "Vous ne serez pas facturé si vous annulez avant la fin de la période d'essai gratuite. Après l'essai, la facturation commence et se renouvelle au début de chaque période de facturation. Vous pouvez annuler à tout moment dans le",
            manage: "Gérer l'abonnement",
            unavailable: {
                title: "Désolé !",
                message:
                    "Les abonnements ne sont actuellement pas disponibles via le web. Pour vous abonner, veuillez visiter votre magasin d'applications et télécharger notre application !"
            },
            policy: {
                text: "En vous abonnant, vous acceptez nos",
                link: "Conditions d'utilisation et Politique de confidentialité"
            }
        },
        welcomemodal: {
            headers: [
                "Apprenez facilement",
                "Obtenez une certification technique aujourd'hui",
                "Abordable et portable"
            ],
            texts: [
                "Apprenez la technologie avec des leçons faciles à comprendre, des quiz et des flashcards cohérents.",
                "Nous facilitons l'obtention d'une certification technique et l'obtention de cet emploi dans le marché du travail compétitif d'aujourd'hui.",
                "Pas de salles de classe, pas de livres et de cours coûteux. Des leçons accessibles et amusantes à portée de main."
            ],
            slide1: {
                title: "Bienvenue sur SkillRocket !",
                text: "Commençons par choisir un parcours professionnel."
            },
            slide2: {
                title: "Génial, maintenant choisissons une certification !",
                text: "Découvrez une leçon gratuite de cette certification professionnelle avant de commencer votre essai gratuit de 7 jours !"
            },
            slide3: {
                title: "Commençons !",
                text: "Choisissez un vaisseau et lancez-vous dans votre première leçon !",
                sub: "Inscrivez-vous et débloquez plus de vaisseaux au fur et à mesure que vous complétez les leçons."
            }
        },
        dashboard: {
            title: "Paramètres",
            account: {
                title: "Compte",
                userInfo: {
                    title: "Infos Utilisateur",
                    header: "Compte",
                    account_info: "Informations du compte",
                    username: {
                        title: "Nom d'utilisateur",
                        header: "Nom d'utilisateur",
                        enter_username:
                            "Veuillez entrer un nouveau nom d'utilisateur ci-dessous:",
                        username_input: {
                            status_message:
                                "Commencez à taper pour vérifier la disponibilité du nom d'utilisateur.",
                            generate_username: "Générer un nom d'utilisateur"
                        }
                    },
                    email: {
                        title: "Email",
                        header: "Email",
                        enter_email:
                            "Veuillez entrer une nouvelle adresse e-mail ci-dessous :"
                    }
                },
                subscription: "Abonnement",
                preferences: {
                    title: "Préférences",
                    header: "Préférences de l'application",
                    sub_header: "Préférences",
                    language: "Langue",
                    dark: "Mode sombre",
                    haptics: "Haptiques",
                    audio: "Son",
                    narration: "Narration"
                }
            },
            support: {
                title: "Soutien",
                support: "Soutien",
                contact: "Nous contacter",
                in_touch: "Restez en contact",
                fill_form_request:
                    "Veuillez remplir le formulaire ci-dessous pour nous contacter.",
                return_email: "Email de retour",
                question: "Avec quoi avez-vous besoin d'aide?",
                response_placeholder: "Tapez votre message ici..."
            },
            login: {
                title: "Connexion",
                delAccount: {
                    title: "Supprimer le compte",
                    header: "Suppression du compte",
                    notice: "Avis",
                    warning:
                        "Une fois que votre compte est supprimé, il sera définitivement retiré de notre système.",
                    enter_email: "Veuillez entrer votre email ci-dessous :",
                    delete: "Supprimer"
                },
                logout: "Déconnexion"
            }
        }
    };
});
