<template>
    <div class="container">
        <img :id="'logo'" src="~/assets/logos/logo-dark.png" alt="Arkitect Logo"
            :style="{ width: imgWidthSizeMap[size] }" />
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
    name: "ArkitectLogo",
    props: {
        size: {
            type: String,
            default: 'sm',
            validator(value) {
                return ['sm', 'md', 'lg'].includes(value);
            }
        }
    },
    computed: {
        imgWidthSizeMap() {
            return {
                sm: '8rem',
                md: '12rem',
                lg: '15rem'
            };
        }
    }
});
</script>

<style scoped>
.container {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

@keyframes float {
    0% {
        transform: translateY(0px);
    }

    50% {
        transform: translateY(-1rem);
    }

    100% {
        transform: translateY(0px);
    }
}

img {
    width: 8rem;
    height: auto;
    animation: float 5s ease-in-out infinite;
}
</style>
