export default defineI18nLocale(async (locale) => {
    return {
        nav: {
            back: "Geri",
            save: "Kaydet",
            submit: "Gönder"
        },
        home: {
            login: {
                title: "Giriş",
                header: "Giriş",
                welcome_back: "Tekrar hoş geldiniz!",
                email: "E-posta",
                password: "Şifre",
                login: "Giriş Yap",
                or: "veya",
                no_account: "Hesabınız yok mu?",
                signup: "Kaydol",
                forgot_password: "Şifrenizi mi unuttunuz?",
                reset_password: "Şifreyi Sıfırla"
            },
            signup: {
                title: "Kaydol",
                questline_selection: {
                    header: "Bir görev hattı seçerek başlayalım.",
                    text: "Seçtiğiniz yol, alacağınız dersleri belirleyecektir.",
                    coming_soon: "Yakında!"
                },
                email: {
                    header: "Sizi kaydedelim!",
                    text: "Sonraki adımda, bir e-posta adresine ihtiyacımız var.",
                    placeholder: "E-posta",
                    next: "İleri",
                    or: "veya"
                },
                details: {
                    header: "Sadece birkaç detaya daha ihtiyacımız var.",
                    name: "İsim",
                    name_placeholder: "İsim (Ad Soyad)",
                    password: {
                        title: "Şifre",
                        placeholder: "Şifre",
                        verify: "Şifreyi Doğrula",
                        password_requirements:
                            "En az 8 karakter, 1 rakam, 1 büyük harf."
                    },
                    signup: {
                        header: "Sizi kaydediyoruz...",
                        text: "Bu sadece bir an sürmelidir..."
                    }
                },
                signin: {
                    header: "Tutun sıkıca!",
                    text: "Sizi giriş yapıyoruz..."
                },
                subscription: {
                    maybe_later: "Belki Daha Sonra"
                },
                action: {
                    header: "Umarız bir şeyler öğrenmişsinizdir!",
                    sub: {
                        1: "Kaydolun ve ",
                        2: "öğrenmeye devam edin",
                        3: " ve CompTIA A+ yolculuğunuzda bugün ilerleme kaydedin!"
                    },
                    button: "Ücretsiz denemenizi başlatın"
                }
            },
            next_lesson: "Sonraki ders",
            launch: "Başlat!",
            privacy: "Gizlilik Politikası ve Hizmet Şartları",
            subscribe: "Daha fazlası için abone olun",
            node_unavailable: "Düğüm kullanılamıyor",
            signup_to_access: "Erişim için kaydolun",
            start_node: "Başla",
            recenter: "Yeniden ortala"
        },
        end_card: {
            header: "Ders tamamlandı:",
            progress_text: "Görev ilerlemesi:",
            earned: "Kazanılan",
            streak: "Seri",
            total: "Toplam",
            shop_ship:
                "Bunları gemi mağazasından gemi satın almak için kullanın!",
            return: "Haritaya Dön"
        },
        streak: {
            header: "İz",
            message: "Bir gün kaçırdığınızda izler sıfırlanır",
            days: "Günler"
        },
        phaserfeedback: {
            title: "Geri Bildirim",
            header: "Ders Geri Bildirimi",
            paragraph:
                "Görüşlerinizi değerli buluyoruz! Lütfen bu ders veya belirli slaytlar hakkındaki düşüncelerinizi paylaşın.",
            share: "Düşüncelerinizi Paylaşın:",
            textarea_placeholder: "Aklınızdan neler geçiyor?",
            rating_placeholder: "Deneyiminizi nasıl değerlendirirsiniz?",
            submit: "Geri Bildirim Gönder"
        },
        uifeedback: {
            title: "Geri Bildirim",
            header: "Sizden Duymak İsteriz!",
            paragraph:
                "Yeniliklerimizin arkasındaki itici güç sizin girdilerinizdir. Görüşlerinizi paylaşmanız, Arkitect'i geliştirmemize, \
                her dersi daha interaktif hale getirmemize ve arayüzümüzü daha kullanıcı dostu yapmamıza yardımcı olur. Ne düşündüğünüzü bize bildirin!",
            share: "Düşüncelerinizi Paylaşın:",
            textarea_placeholder: "Aklınızdan neler geçiyor?",
            submit: "Geri Bildirim Gönder"
        },
        friends: {
            title: "Arkadaşlar",
            case_sensitive: "Kullanıcı adları büyük/küçük harfe duyarlıdır",
            friends_list: "Arkadaş Listesi",
            incoming_requests: "Gelen İstekler",
            outgoing_requests: "Giden İstekler",
            add_user: "Kullanıcı Ekle",
            remove_user: "Kaldır",
            accept_user: "Kabul Et",
            reject_user: "Reddet",
            cancel_request: "İptal Et"
        },
        shop: {
            title: "Gemi Mağazası",
            welcome: "Mağazaya Hoş Geldiniz!",
            selected: "Seçilen",
            select: "Seç",
            owned: "Sahip Olunan",
            purchase: "Satın Al",
            unavailable: "Yetersiz"
        },
        patchnotes: {
            title: "Yama Notları",
            version: "Sürüm",
            changes: [
                "✨ <b>Tam Tasarım Yenilemesi</b><br>&nbsp;&nbsp;- Uygulamayı yeni bir tema, güncellenmiş renkler ve genel görünümle tamamen yeniledik.",
                "⚙️ <b>Backend ve Frontend Yenilemesi</b><br>&nbsp;&nbsp;- Backend ve frontend üzerinde büyük güncellemeler yaptık—uygulamanın temel işlevselliğinin yaklaşık %50'sini yeniden yazdık, böylece her şey daha sorunsuz çalışıyor.",
                "📚 <b>Yeni Bir Ders: Şifreleme</b><br>&nbsp;&nbsp;- Şifreleme üzerine tamamen yeni bir ders artık mevcut. Bu ders, uygulamanın yeni tasarımıyla uyumlu güncellenmiş ders stilimizi içeriyor.",
                "👋 <b>Misafir Erişimi Güncellendi</b><br>&nbsp;&nbsp;- Mevcut misafir dersi, yeni şifreleme içeriğini içerecek şekilde güncellendi.",
                "🌐 <b>PayPal Destekli Web Uygulaması Başlatıldı</b><br>&nbsp;&nbsp;- <a href='https://learn.skillrocket.ca' target='_blank'>learn.skillrocket.ca</a> adresinde web uygulamamızı başlattığımızı duyurmaktan heyecan duyuyoruz. Bu uygulama artık PayPal ödemelerini destekliyor."
            ]
        },
        subscriptions: {
            early_access: "Erken Erişim",
            unlimited: "Sınırsız erişim",
            new_content: "Her hafta yeni dersler",
            review: "Özelleştirilmiş hafıza kartları ve sınavlarla gözden geçirme",
            prep: "Popüler sertifikalara hazırlık",
            trial: "... ve ilk hafta ücretsiz",
            new: "(Yeni müşteriler için)",
            week: "1 haftalık deneme +",
            button: {
                already_subscribed: "Zaten Abone",
                subscribe: "Abone Ol"
            },
            billing:
                "Ücretsiz deneme süresi sona ermeden iptal ederseniz ücretlendirilmeyeceksiniz. Deneme sonrası, faturalama başlar ve her fatura döneminin başında yenilenir. Herhangi bir zamanda",
            manage: "Abonelik Yönetimi",
            unavailable: {
                title: "Üzgünüz!",
                message:
                    "Abonelikler şu anda web üzerinden mevcut değil. Abone olmak için, lütfen uygulama mağazanızı ziyaret edin ve uygulamamızı indirin!"
            },
            policy: {
                text: "Abone olarak, bizim",
                link: "Hizmet Şartlarını ve Gizlilik Politikasını kabul etmiş olursunuz"
            }
        },
        welcomemodal: {
            headers: [
                "Kolayca öğrenin",
                "Bugün teknoloji sertifikası alın",
                "Uygun fiyatlı ve taşınabilir"
            ],
            texts: [
                "Kolay anlaşılır dersler, tutarlı sınavlar ve hafıza kartları ile teknolojiyi öğrenin.",
                "Günümüzün rekabetçi iş gücünde teknoloji sertifikası almayı ve o işi bulmayı kolaylaştırıyoruz.",
                "Sınıf yok, pahalı kitaplar ve kurslar yok. Parmaklarınızın ucunda erişilebilir ve eğlenceli dersler."
            ],
            slide1: {
                title: "SkillRocket'e Hoş Geldiniz!",
                text: "Bir kariyer yolu seçerek başlayalım."
            },
            slide2: {
                title: "Harika, şimdi bir sertifika seçelim!",
                text: "7 günlük ücretsiz denemenize başlamadan önce bu profesyonel sertifikadan bir ücretsiz dersi keşfedin!"
            },
            slide3: {
                title: "Hadi başlayalım!",
                text: "Bir gemi seç ve ilk dersine başla!",
                sub: "Kaydolun ve dersleri tamamladıkça daha fazla geminin kilidini açın."
            }
        },
        dashboard: {
            title: "Ayarlar",
            account: {
                title: "Hesap",
                userInfo: {
                    title: "Kullanıcı Bilgisi",
                    header: "Hesap",
                    account_info: "Hesap Bilgileri",
                    username: {
                        title: "Kullanıcı Adı",
                        header: "Kullanıcı Adı",
                        enter_username:
                            "Lütfen aşağıya yeni bir kullanıcı adı girin:",
                        username_input: {
                            status_message:
                                "Kullanıcı adı uygunluğunu kontrol etmek için yazmaya başlayın.",
                            generate_username: "Kullanıcı Adı Oluştur"
                        }
                    },
                    email: {
                        title: "E-posta",
                        header: "E-posta",
                        enter_email:
                            "Lütfen aşağıya yeni bir e-posta adresi girin:"
                    }
                },
                subscription: "Abonelik",
                preferences: {
                    title: "Tercihler",
                    header: "Uygulama Tercihleri",
                    sub_header: "Tercihler",
                    language: "Dil",
                    dark: "Karanlık Mod",
                    haptics: "Dokunsal Geri Bildirim",
                    audio: "Ses",
                    narration: "Anlatım"
                }
            },
            support: {
                title: "Destek",
                support: "Destek",
                contact: "Bize Ulaşın",
                in_touch: "İletişime Geçin",
                fill_form_request:
                    "Lütfen bizimle iletişime geçmek için aşağıdaki formu doldurun.",
                return_email: "Dönüş E-postası",
                question: "Hangi konuda yardıma ihtiyacınız var?",
                response_placeholder: "Mesajınızı buraya yazın..."
            },
            login: {
                title: "Giriş",
                delAccount: {
                    title: "Hesabı Sil",
                    header: "Hesap silme",
                    notice: "Uyarı",
                    warning:
                        "Hesabınız silindiğinde, hesabınız sistemimizden kalıcı olarak kaldırılacaktır.",
                    enter_email: "Lütfen e-posta adresinizi aşağıya girin:",
                    delete: "Sil"
                },
                logout: "Çıkış Yap"
            }
        }
    };
});
