export const questions = [
    {
        first_time: true,
        id: 0,
        node: -5,
        options: {
            ar: {
                answers: [
                    "لتسريع نقل البيانات",
                    "لتجنب الفاعلين السيئين تمامًا",
                    "لإبقاء البيانات غير قابلة للقراءة للآخرين",
                    "لتقليل التوقف أثناء النقل"
                ],
                correctAnswer: "لإبقاء البيانات غير قابلة للقراءة للآخرين"
            },
            en: {
                answers: [
                    "To speed up data transfers.",
                    "To avoid bad actors entirely.",
                    "To keep data unreadable to others.",
                    "To reduce stops during transmission."
                ],
                correctAnswer: "To keep data unreadable to others."
            },
            fr: {
                answers: [
                    "Accélérer les transferts de données.",
                    "Éviter complètement les acteurs malveillants.",
                    "Rendre les données illisibles pour les autres.",
                    "Réduire les arrêts pendant la transmission."
                ],
                correctAnswer: "Rendre les données illisibles pour les autres."
            },
            tr: {
                answers: [
                    "Veri aktarımını hızlandırmak.",
                    "Kötü aktörlerden tamamen kaçınmak.",
                    "Verileri başkalarına okunamaz hale getirmek.",
                    "İletim sırasında duraklamaları azaltmak."
                ],
                correctAnswer: "Verileri başkalarına okunamaz hale getirmek."
            }
        },
        question: {
            ar: "لماذا يعتبر التشفير مهمًا لتأمين البيانات على الإنترنت؟",
            en: "Why is encryption important for securing data on the Internet?",
            fr: "Pourquoi le cryptage est-il important pour sécuriser les données sur Internet ?",
            tr: "İnternetteki verilerin güvenliğini sağlamak için şifreleme neden önemlidir?"
        },
        quiz_type: "Squares",
        slot: 0
    },
    {
        first_time: true,
        id: 1,
        node: -5,
        options: {
            ar: {
                answers: [
                    "الرسائل تستغرق وقتًا طويلاً لفك تشفيرها.",
                    "يجب أيضًا مشاركة المفتاح.",
                    "يمكنه فقط تشفير الرسائل القصيرة.",
                    "يتطلب أدوات متعددة لفك التشفير."
                ],
                correctAnswer: "يجب أيضًا مشاركة المفتاح."
            },
            en: {
                answers: [
                    "Messages take too long to decode.",
                    "The key must also be shared.",
                    "It can only encrypt short messages.",
                    "Requires multiple tools for decryption."
                ],
                correctAnswer: "The key must also be shared."
            },
            fr: {
                answers: [
                    "Les messages prennent trop de temps à décoder.",
                    "La clé doit également être partagée.",
                    "Il ne peut crypter que des messages courts.",
                    "Nécessite plusieurs outils pour le décryptage."
                ],
                correctAnswer: "La clé doit également être partagée."
            },
            tr: {
                answers: [
                    "Mesajların çözülmesi çok uzun sürer.",
                    "Anahtar da paylaşılmalıdır.",
                    "Sadece kısa mesajları şifreleyebilir.",
                    "Birden fazla araç gerektirir."
                ],
                correctAnswer: "Anahtar da paylaşılmalıdır."
            }
        },
        question: {
            ar: "ما هي المشكلة الرئيسية مع Scytale و Symmetric Encryption؟",
            en: "What is the main problem with the Scytale and Symmetric Encryption?",
            fr: "Quel est le principal problème avec le Scytale et le cryptage symétrique ?",
            tr: "Scytale ve Simetrik Şifreleme ile ilgili ana sorun nedir?"
        },
        quiz_type: "Squares",
        slot: 1
    },
    {
        first_time: true,
        id: 2,
        node: -5,
        options: {
            ar: {
                answers: [
                    "يتجنب مشاركة مفاتيح التشفير.",
                    "يسرّع الاتصالات الآمنة.",
                    "يتطلب وجود جاسوس واحد فقط.",
                    "يجعل الأقفال الفيزيائية غير ضرورية."
                ],
                correctAnswer: "يتجنب مشاركة مفاتيح التشفير."
            },
            en: {
                answers: [
                    "It avoids sharing encryption keys.",
                    "It speeds up secure communication.",
                    "It requires only one spy to act.",
                    "It makes physical locks unnecessary."
                ],
                correctAnswer: "It avoids sharing encryption keys."
            },
            fr: {
                answers: [
                    "Évite le partage des clés de cryptage.",
                    "Accélère la communication sécurisée.",
                    "Nécessite seulement un espion.",
                    "Rend les verrous physiques inutiles."
                ],
                correctAnswer: "Évite le partage des clés de cryptage."
            },
            tr: {
                answers: [
                    "Şifreleme anahtarlarını paylaşmaktan kaçınır.",
                    "Güvenli iletişimi hızlandırır.",
                    "Sadece bir casusun hareket etmesini gerektirir.",
                    "Fiziksel kilitleri gereksiz kılar."
                ],
                correctAnswer: "Şifreleme anahtarlarını paylaşmaktan kaçınır."
            }
        },
        question: {
            ar: "ما هي الميزة الرئيسية لـ Asymmetric Encryption؟",
            en: "What is the key advantage of Asymmetric Encryption?",
            fr: "Quel est l'avantage clé du cryptage asymétrique ?",
            tr: "Asimetrik Şifrelemenin ana avantajı nedir?"
        },
        quiz_type: "Squares",
        slot: 2
    },
    {
        first_time: true,
        id: 3,
        node: -5,
        options: {
            ar: {
                answers: [
                    "يتم مشاركتها فقط مع المرسلين الموثوق بهم.",
                    "لا يمكن استنتاجها رياضياً.",
                    "يتم ترميزها داخل المفتاح العام.",
                    "تتغير مع كل استخدام."
                ],
                correctAnswer: "لا يمكن استنتاجها رياضياً."
            },
            en: {
                answers: [
                    "It’s only shared with trusted senders.",
                    "It’s kept secret and impossible to deduce.",
                    "It’s encoded within the Public Key.",
                    "It changes with every use."
                ],
                correctAnswer: "It’s kept secret and impossible to deduce."
            },
            fr: {
                answers: [
                    "Elle est seulement partagée avec des expéditeurs de confiance.",
                    "Elle est gardée secrète et impossible à déduire.",
                    "Elle est encodée dans la clé publique.",
                    "Elle change à chaque utilisation."
                ],
                correctAnswer:
                    "Elle est gardée secrète et impossible à déduire."
            },
            tr: {
                answers: [
                    "Sadece güvenilir göndericilerle paylaşılır.",
                    "Gizli tutulur ve çözülmesi imkansızdır.",
                    "Genel Anahtar içinde kodlanmıştır.",
                    "Her kullanımda değişir."
                ],
                correctAnswer: "Gizli tutulur ve çözülmesi imkansızdır."
            }
        },
        question: {
            ar: "لماذا يعتبر المفتاح الخاص في التشفير غير متماثل آمنًا؟",
            en: "Why is the Private Key in Asymmetric Encryption secure?",
            fr: "Pourquoi la clé privée en cryptage asymétrique est-elle sécurisée ?",
            tr: "Asimetrik Şifrelemede Özel Anahtar neden güvenlidir?"
        },
        quiz_type: "Squares",
        slot: 3
    }
];

export const scene_text = {
    en: {
        Scene1: {
            text: [
                "Encryption is the technology that helps keep our information safe and secure. It is one of the most important inventions ever built, and we rely on it every day.",
                "Nefarious actors like hackers and big corporations are constantly snooping for our information, but encryption makes it unreadable and inaccessible to them."
            ]
        },
        Scene2: {
            text: [
                "Since we share the internet with one another, it isn't feasible to sneak our data past these adversaries; instead, we scramble it in a way that only we can unscramble.",
                "It's important because the Internet is a vast web of cables and servers that allow us to send data across the globe.",
                "When we send data to someone across the internet, it passes through countless cables and servers, making hundreds of stops along the way.",
                "Imagine sending a letter to a friend. As it travels through multiple depots and warehouses, anyone at those places, interested in stealing your information, could open it up and read your message.",
                "Encryption prevents that issue altogether by scrambling our message and rendering it unreadable—locked away for anyone but those with the key."
            ],
            data: "data"
        },
        Scene3: {},
        Scene4: {
            text: [
                "Let’s start our lesson by learning from the Ancient Spartans and how they developed one of the earliest forms of encryption.",
                "As Spartan armies conquered more territory, lots of messages had to be sent to coordinate between each of them. Unfortunately, enemies noticed the messengers, started intercepting them, and stole sensitive plans.",
                "Battles were being lost, so the Spartans invented one of the first forms of encryption, the Scytale. An ingenious tool that allowed messages to be scrambled and unscrambled easily on the battlefield.",
                "Spartan generals would wind a strip of leather around a wooden Scytale and inscribe their plans across each band of leather one letter at a time.",
                "Wonderfully, the strip of leather would appear as a random sequence of letters when it was unwound from the Scytale it was written on. This meant that it was challenging to decrypt (unscramble) the message without the original Scytale.",
                "Messengers didn't want to be caught so they obscured the leather strip even more by disguising it as a belt, allowing them to deliver a ‘meaningless’ wooden rod across enemy lines. The Scytale worked well and gave the Spartan armies the upper hand.",
                "This is symmetric encryption, a system that relies on one lock and one key. If I lock something and send it to you, I’ll need to send you the key as well in order for you to unlock it. Encrypting and decrypting messages is fast and straightforward.",
                "Unfortunately for the Spartans, symmetric encryption has a big flaw. Enemies took notice of the cryptic belts and wooden rods being sent between armies and began decrypting the messages themselves.",
                "This highlights the biggest issue with symmetric encryption: that the key has to be sent at some point to decrypt the scrambled message. This seemed like an unsolvable issue for a long time—until the 20th century."
            ],
            fast: "FAST",
            dialog1: "Are those enemy plans?!",
            dialog2: "No enemy plans detected...",
            dialog3: "Move along!",
            dialog4: "Wait!",
            dialog5: "That's a Scytale!",
            insecure: "INSECURE",
            encrypted: "ENCRYPTED",
            scytaleMessage: "IMMEDIATELY SEND TROOPS TO THE NORTHERN FLANK",
            symmetricEncryption: "SYMMETRIC\nENCRYPTION"
        },
        Scene5: {},
        Scene6: {
            messageDelivered: "MESSAGE DELIVERED!",
            endlessRunnerGameName: "SCYTALE\nRUN",
            shareScore: "SHARE YOUR BEST SCORE!",
            tutorial: "TAP TO SWITCH LANES",
            tutorial2: "DODGE GUARDS AND DELIVER SCYTALES!",
            tryAgain: "TRY AGAIN",
            luckRollSuccess: "You're clear.\nMove on.",
            luckRollFail: "THIS IS A SCYTALE!\nGET BACK HERE!",
            coinFlipHeader: "LET FATE DECIDE!",
            coinFlipSuccess: "YOU'RE\nSAFE!",
            coinFlipFail: "THEY\nFOUND\nTHE\nMESSAGE!"
        },
        Scene7: {
            slow: "SLOW",
            text: [
                "Moving forward through time, let’s learn about the second type of encryption through the Cold War. An era where one mistake could disrupt the delicate balance between the United States and the Soviet Union.",
                "Imagine that two rival spies, one from the United States and the other from the Soviet Union, are working together to establish a peace treaty.",
                "Trust was scarce and neither side wanted to rely on symmetric encryption. Trying to send the key to each other was risky, and losing it could become a world-ending mistake. Instead, they devised a clever plan.",
                "The American spy placed the signed peace treaty inside of a sturdy briefcase, secured it with a padlock, and left it on a park bench.",
                "Their Soviet counterpart retrieved the locked briefcase and placed their own padlock on it alongside the American padlock. Then, the Soviet agent left the doubly-locked briefcase on that same park bench.",
                "The American spy retrieved the briefcase, removed their lock, and returned it to the park bench, still locked shut.",
                "Finally, the Soviet agent retrieved the briefcase for the last time, removed their lock, and revealed the signed peace treaty—securely delivered and ready to end the war.",
                "This is asymmetric encryption. It's designed to overcome symmetric encryption's biggest flaw, the need to send a key. Asymmetric encryption avoids the need by using two seperate locks and keeping the message secured even if it were to be intercepted.",
                "However, this added security comes at a cost - it is slow. To securely send something using basic asymmetric encryption, a message must travel back and forth multiple times before being successfully delivered."
            ],
            secure: "SECURE",
            tooSensitive: "Too sensitive",
            symmetricEncryption: "SYMMETRIC\nENCRYPTION"
        },
        Scene8: {},
        Scene9: {
            memoryGameName: "MEMORY\nSPIES",
            shareScore: "SHARE YOUR BEST SCORE!",
            spyTutorialText: "I was just kidding...",
            tryAgain: "TRY AGAIN"
        },
        Scene10: {
            text: [
                "Modern society needed an encryption method that was much faster and just as secure. In the 1970s, asymmetric encryption got a much-needed upgrade thanks to a breakthrough idea. Instead of using two locks, you could provide a potential sender with one lock that you already have the key to open.",
                "Imagine that Blue wants to communicate with their friend Red. Blue copies their lock and then makes that copy openly available for everyone to see, while the key to that lock is kept secret.",
                "Red can then make a copy of Blue’s publicly available lock, secure a message with it, and send it to Blue knowing that they already have the key to unlock and unscramble it.",
                "In reality, the lock and key correspond to two incredibly large numbers. Your publicly available lock is called a 'Public Key' and the corresponding key to open it is called a 'Private Key'.",
                "The public and private key numbers share a special mathematical bond that allows them to seamlessly scramble and unscramble messages. Even though the mathematics behind these processes is common knowledge and your ‘lock’ is made public, modern encryption remains virtually unbreakable.",
                "This is modern Asymmetric Encryption, more specifically RSA, one of the most common encryption methods in the world. The return to a 'single-lock' system drastically simplified the exchange process and revolutionized the Internet, enabling fast and secure communication.",
                "Since the 1970s, encryption has only gotten stronger with the emergence of even more uncrackable algorithms. RSA, SHA, and now ECC have become some of the most important technologies ever built.\n\nThanks to Encryption we can maintain our privacy while instantly communicating with each other across the world."
            ],
            publicKey: "PUBLIC KEY",
            privateKey: "PRIVATE KEY",
            secure: "SECURE",
            fast: "FAST",
            symmetricEncryption: "SYMMETRIC\nENCRYPTION",
            modern: "MODERN"
        },
        Scene11: {},
        Scene12: {
            bulletHellGameName: "ENCRYPTED\nESCAPE",
            shareScore: "SHARE YOUR BEST SCORE!",
            tutorial: "TAP AND HOLD\nTO MOVE AND SHOOT!",
            tryAgain: "TRY AGAIN"
        },
        Scene13: {}
    },
    fr: {
        Scene1: {
            text: [
                "Le chiffrement est la technologie qui aide à garder nos informations sûres et sécurisées. C'est l'une des inventions les plus importantes jamais créées, et nous en dépendons chaque jour.",
                "Des acteurs malveillants comme les pirates informatiques et les grandes entreprises espionnent constamment nos informations, mais le chiffrement les rend illisibles et inaccessibles pour eux."
            ]
        },
        Scene2: {
            text: [
                "Puisque nous partageons Internet les uns avec les autres, il n'est pas faisable de dissimuler nos données à ces adversaires ; à la place, nous les brouillons d'une manière que seuls nous pouvons déchiffrer.",
                "Internet est un vaste réseau de câbles et de serveurs qui nous permet d'envoyer des données à travers le monde.",
                "Lorsque nous envoyons des données à quelqu'un sur Internet, elles passent par d'innombrables câbles et serveurs, faisant des centaines d'arrêts en chemin.",
                "Imaginez envoyer une lettre à un ami. Alors qu'elle traverse plusieurs dépôts et entrepôts, n'importe qui à ces endroits, intéressé par le vol de vos informations, pourrait l'ouvrir et lire votre message.",
                "Le chiffrement empêche complètement ce problème en brouillant notre message et en le rendant illisible — verrouillé pour tous sauf ceux qui détiennent la clé."
            ],
            data: "data"
        },
        Scene3: {},
        Scene4: {
            text: [
                "Commençons notre leçon en apprenant des anciens Spartiates et comment ils ont développé l'une des premières formes de chiffrement.",
                "À mesure que les armées spartiates conquéraient plus de territoires, de nombreux messages devaient être envoyés pour coordonner entre elles. Malheureusement, les ennemis ont remarqué les messagers, ont commencé à les intercepter et ont volé des plans sensibles.",
                "Des batailles étaient perdues, alors les Spartiates ont inventé l'une des premières formes de chiffrement, le Scytale. Un outil ingénieux qui permettait de brouiller et de déchiffrer facilement des messages.",
                "Les généraux spartiates enroulaient une bande de cuir autour d'un Scytale en bois et y inscrivaient leurs plans à travers chaque bande de cuir.",
                "Merveilleusement, la bande de cuir apparaissait comme une séquence aléatoire de lettres lorsqu'elle était déroulée du Scytale sur lequel elle était écrite. Cela signifiait qu'il était difficile de déchiffrer (désordonner) le message sans le Scytale original.",
                "Les messagers obscurcissaient encore plus le message caché en le déguisant en ceinture, leur permettant de livrer une tige en bois « insignifiante » à travers les lignes ennemies. Le Scytale fonctionnait bien et donnait un avantage aux armées spartiates.",
                "C'est le chiffrement symétrique, un système qui repose sur une serrure et une clé uniques. Si je verrouille quelque chose et que je vous l'envoie, je devrai également vous envoyer la clé pour que vous puissiez le déverrouiller. Chiffrer et déchiffrer des messages est rapide et simple.",
                "Malheureusement pour les Spartiates, le chiffrement symétrique a un gros défaut. Les ennemis ont remarqué les ceintures cryptiques et les tiges en bois envoyées entre les armées et ont commencé à déchiffrer eux-mêmes les messages.",
                "Cela met en évidence le plus gros problème du chiffrement symétrique : la clé doit être envoyée à un moment donné pour déchiffrer le message brouillé. Cela a semblé être un problème insoluble pendant longtemps — jusqu'au XXe siècle."
            ],
            fast: "RAPIDE",
            dialog1: "Ce sont des plans ennemis ?!",
            dialog2: "Aucun plan ennemi détecté...",
            dialog3: "Circulez !",
            dialog4: "Attendez !",
            dialog5: "C'est un Scytale !",
            insecure: "NON SÉCURISÉ",
            encrypted: "CRYPTÉ",
            scytaleMessage: "IMMEDIATELY SEND TROOPS TO THE NORTHERN FLANK",
            symmetricEncryption: "SYMMETRIC\nENCRYPTION"
        },
        Scene5: {},
        Scene6: {
            messageDelivered: "MESSAGE LIVRÉ !",
            endlessRunnerGameName: "SCYTALE\nRUN",
            shareScore: "PARTAGEZ VOTRE MEILLEUR SCORE !",
            tutorial: "APPUYEZ POUR CHANGER DE LIGNE",
            tutorial2: "ÉVITEZ LES GARDES ET LIVREZ LES SCYTALES !",
            tryAgain: "ESSAYEZ À NOUVEAU",
            luckRollSuccess: "C'est bon.\nContinuez.",
            luckRollFail: "C'EST UN SCYTALE !\nREVENEZ ICI !",
            coinFlipHeader: "LAISSEZ LE DESTIN DÉCIDER !",
            coinFlipSuccess: "VOUS ÊTES\nEN SÉCURITÉ !",
            coinFlipFail: "ILS\nONT\nTROUVÉ\nLE MESSAGE !"
        },
        Scene7: {
            slow: "LENT",
            text: [
                "Avançons dans le temps pour apprendre le deuxième type de chiffrement pendant la Guerre froide. Une époque où une seule erreur pouvait perturber l'équilibre délicat entre les États-Unis et l'Union soviétique.",
                "Imaginez que deux espions rivaux, l'un des États-Unis et l'autre de l'Union soviétique, travaillent ensemble pour établir un traité de paix.",
                "La confiance était rare et aucune des parties ne voulait compter sur le chiffrement symétrique. Essayer d'envoyer la clé à l'autre était risqué, et la perdre pourrait devenir une erreur fatale. À la place, ils ont conçu un plan astucieux.",
                "L'espion américain a placé le traité de paix signé dans une valise solide, l'a sécurisée avec un cadenas et l'a laissée sur un banc de parc.",
                "Son homologue soviétique a récupéré la valise verrouillée et y a placé son propre cadenas à côté du cadenas américain. Puis, l'agent soviétique a laissé la valise doublement verrouillée sur le même banc de parc.",
                "L'espion américain a récupéré la valise, a retiré son cadenas et l'a renvoyée sur le banc de parc, toujours verrouillée.",
                "Enfin, l'agent soviétique a récupéré la valise pour la dernière fois, a retiré son cadenas et a révélé le traité de paix signé — livré en toute sécurité et prêt à mettre fin à la guerre.",
                "C'est le chiffrement asymétrique, qui surmonte le plus grand défaut du chiffrement symétrique en utilisant deux serrures et en n'envoyant pas de clé du tout. Il est beaucoup plus sûr.",
                "Cependant, cette sécurité supplémentaire a un coût — c'est lent. Pour envoyer quelque chose en toute sécurité avec cette méthode, un message doit faire trois allers-retours avant d'être livré."
            ],
            secure: "SÉCURISÉ",
            tooSensitive: "Trop sensible",
            symmetricEncryption: "SYMMETRIC\nENCRYPTION"
        },
        Scene8: {},
        Scene9: {
            memoryGameName: "MEMORY\nSPIES",
            shareScore: "PARTAGEZ VOTRE MEILLEUR SCORE !",
            spyTutorialText: "Je plaisantais...",
            tryAgain: "ESSAYEZ À NOUVEAU"
        },
        Scene10: {
            text: [
                "La société moderne avait besoin d'une méthode de chiffrement beaucoup plus rapide et tout aussi sûre. Dans les années 1970, le chiffrement asymétrique a bénéficié d'une mise à jour bien nécessaire grâce à une idée révolutionnaire. Au lieu d'utiliser deux serrures, vous pouviez en envoyer une que vous avez la clé pour déverrouiller.",
                "Imaginez que Bleu veut communiquer avec son ami Rouge. Bleu copie son cadenas et rend cette copie publique pour que tout le monde puisse la voir, tandis que la clé pour le déverrouiller est gardée secrète et sécurisée.",
                "Rouge copie le modèle et recrée une copie du cadenas de Bleu, puis Rouge sécurise et envoie un message à Bleu en sachant qu'il a déjà la clé pour le déverrouiller.",
                "Le cadenas et la clé sont en réalité deux nombres incroyablement grands. Votre « cadenas » disponible publiquement est appelé une clé publique et la « clé » correspondante gardée secrète est appelée une clé privée.",
                "Les numéros de clé publique et privée partagent un lien mathématique spécial qui leur permet de brouiller et de déchiffrer les messages de manière transparente. Même si les mathématiques derrière ces processus sont de notoriété publique et que votre « cadenas » est rendu public, le chiffrement moderne reste pratiquement incassable.",
                "C'est le chiffrement asymétrique moderne, spécifiquement l'une des variétés les plus courantes, RSA. Le retour à un système à serrure unique a considérablement simplifié le processus d'échange et révolutionné Internet, permettant une communication rapide et sécurisée.",
                "Depuis les années 1970, le chiffrement a évolué avec des algorithmes encore plus incassables. RSA, SHA, et maintenant ECC ont émergé comme certaines des technologies les plus importantes jamais développées."
            ],
            publicKey: "CLÉ PUBLIQUE",
            privateKey: "CLÉ PRIVÉE",
            secure: "SÉCURISÉ",
            fast: "RAPIDE",
            symmetricEncryption: "SYMMETRIC\nENCRYPTION",
            modern: "MODERN"
        },
        Scene11: {},
        Scene12: {
            bulletHellGameName: "ENCRYPTED\nESCAPE",
            shareScore: "PARTAGEZ VOTRE MEILLEUR SCORE !",
            tutorial: "APPUYEZ ET MAINTENEZ\nPOUR DÉPLACER ET TIRER !",
            tryAgain: "ESSAYEZ À NOUVEAU"
        },
        Scene13: {}
    },
    ar: {
        Scene1: {
            text: [
                "التشفير هو التقنية التي تساعد في الحفاظ على معلوماتنا آمنة ومحمية. إنه أحد أهم الاختراعات التي تم تطويرها على الإطلاق، ونعتمد عليه كل يوم.",
                "جهات خبيثة مثل القراصنة والشركات الكبرى تسعى باستمرار للتجسس على معلوماتنا، ولكن التشفير يجعلها غير قابلة للقراءة والوصول إليها."
            ]
        },
        Scene2: {
            text: [
                "نظرًا لأننا نشارك الإنترنت مع بعضنا البعض، فإنه من غير الممكن تمرير بياناتنا خلسة أمام هؤلاء الخصوم؛ وبدلاً من ذلك، نقوم بتشفيرها بطريقة لا يمكن فك تشفيرها إلا من قبلنا.",
                "الإنترنت هو شبكة ضخمة من الكابلات والخوادم التي تتيح لنا إرسال البيانات حول العالم.",
                "عندما نرسل بيانات إلى شخص ما عبر الإنترنت، تمر عبر عدد لا يحصى من الكابلات والخوادم، وتقوم بمئات المحطات على طول الطريق.",
                "تخيل إرسال رسالة إلى صديق. أثناء انتقالها عبر العديد من المستودعات والمحطات، يمكن لأي شخص في تلك الأماكن، إذا كان مهتمًا بسرقة معلوماتك، أن يفتحها ويقرأ رسالتك.",
                "التشفير يحل هذه المشكلة تمامًا عن طريق تشويش رسالتنا وجعلها غير قابلة للقراءة — مغلقة لأي شخص إلا لمن يملك المفتاح."
            ],
            data: "data"
        },
        Scene3: {},
        Scene4: {
            text: [
                "لنبدأ درسنا بتعلم كيفية استخدام الإغريق القدماء، وتحديدًا الإسبارطيين، لأحد أشكال التشفير الأولى.",
                "مع توسع جيوش الإسبارطيين في الأراضي، كان يجب إرسال العديد من الرسائل للتنسيق بينهم. ولكن للأسف، لاحظ الأعداء هؤلاء الرسل وبدأوا في اعتراضهم وسرقة الخطط الحساسة.",
                "تم فقد العديد من المعارك، لذا ابتكر الإسبارطيون أحد أشكال التشفير الأولى، وهو السيتيال. أداة ذكية تتيح تشفير الرسائل وفك تشفيرها بسهولة.",
                "كان الجنرالات الإسبارطيون يلفون شريطًا من الجلد حول أسطوانة خشبية تسمى السيتيال ويكتبون خططهم عبر كل شريط من الجلد.",
                "كان من الرائع أن يظهر الشريط الجلدي كسلسلة عشوائية من الأحرف عندما يُفك من الأسطوانة الخشبية التي كتب عليها. وهذا يعني أنه كان من الصعب فك تشفير الرسالة دون وجود السيتيال الأصلي.",
                "كان الرسل يزيدون من تعقيد الرسالة المشفرة عن طريق تمويهها كحزام، مما يتيح لهم إيصال قطعة خشبية «بلا معنى» عبر خطوط العدو. عمل السيتيال بشكل جيد وأعطى الجيوش الإسبارطية الأفضلية.",
                "هذا هو التشفير المتماثل، وهو نظام يعتمد على قفل ومفتاح واحد. إذا قمت بقفل شيء وأرسلته إليك، سأحتاج إلى إرسال المفتاح أيضًا لكي تتمكن من فتحه. التشفير وفك التشفير بهذه الطريقة سريع وبسيط.",
                "ولكن لسوء الحظ بالنسبة للإسبارطيين، كان للتشفير المتماثل عيب كبير. لاحظ الأعداء الأحزمة المشفرة والأعمدة الخشبية المرسلة بين الجيوش وبدأوا في فك تشفير الرسائل بأنفسهم.",
                "هذا يبرز أكبر مشكلة في التشفير المتماثل: يجب إرسال المفتاح في مرحلة ما لفك تشفير الرسالة المشفرة. هذا بدا وكأنه مشكلة لا حل لها لفترة طويلة — حتى القرن العشرين."
            ],
            fast: "سريع",
            dialog1: "هل هذه خطط العدو؟!",
            dialog2: "لم يتم اكتشاف أي خطط للعدو...",
            dialog3: "تابع التحرك!",
            dialog4: "انتظر!",
            dialog5: "إنها سكيتال!",
            insecure: "غير آمن",
            encrypted: "مشفّر",
            scytaleMessage: "IMMEDIATELY SEND TROOPS TO THE NORTHERN FLANK",
            symmetricEncryption: "SYMMETRIC\nENCRYPTION"
        },
        Scene5: {},
        Scene6: {
            messageDelivered: "تم توصيل الرسالة!",
            endlessRunnerGameName: "SCYTALE\nRUN",
            shareScore: "شارك أفضل نتيجتك!",
            tutorial: "اضغط للتبديل بين المسارات",
            tutorial2: "تجنب الحراس ووصّل السكيتال!",
            tryAgain: "حاول مرة أخرى",
            luckRollSuccess: "أنت بأمان.\nتابع التحرك.",
            luckRollFail: "هذه سكيتال!\nارجع إلى هنا!",
            coinFlipHeader: "دع القدر يقرر!",
            coinFlipSuccess: "أنت\nبأمان!",
            coinFlipFail: "لقد\nاكتشفوا\nالرسالة!"
        },
        Scene7: {
            slow: "بطيء",
            text: [
                "دعونا ننتقل عبر الزمن لنتعلم عن النوع الثاني من التشفير خلال الحرب الباردة. وهي حقبة كان فيها خطأ واحد يمكن أن يخل بالتوازن الدقيق بين الولايات المتحدة والاتحاد السوفييتي.",
                "تخيل أن جاسوسين متنافسين، أحدهما من الولايات المتحدة والآخر من الاتحاد السوفييتي، يعملان معًا لإنشاء معاهدة سلام.",
                "كان الثقة نادرة ولم يرغب أي طرف في الاعتماد على التشفير المتماثل. كانت محاولة إرسال المفتاح للآخر محفوفة بالمخاطر، وفقدانه يمكن أن يصبح خطأ كارثيًا. لذا، وضعوا خطة ذكية.",
                "وضع الجاسوس الأمريكي المعاهدة الموقعة داخل حقيبة متينة، وأغلقها بقفل، وتركها على مقعد في الحديقة.",
                "قام نظيره السوفييتي باسترداد الحقيبة المقفلة ووضع قفله الخاص بجانب القفل الأمريكي. ثم ترك العميل السوفييتي الحقيبة المزدوجة القفل على نفس المقعد.",
                "استعاد الجاسوس الأمريكي الحقيبة، وأزال قفله وأعادها إلى المقعد، ولا تزال مغلقة.",
                "أخيرًا، استعاد العميل السوفييتي الحقيبة للمرة الأخيرة، وأزال قفله وكشف عن المعاهدة الموقعة — تم تسليمها بأمان وجاهزة لإنهاء الحرب.",
                "هذا هو التشفير غير المتماثل، الذي يتغلب على أكبر عيوب التشفير المتماثل باستخدام قفلين وعدم إرسال أي مفتاح. إنه أكثر أمانًا بكثير.",
                "ولكن هذه الأمانة الإضافية تأتي بتكلفة — إنها بطيئة. لإرسال شيء بأمان باستخدام هذه الطريقة، يجب أن يقوم الرسالة بثلاثة انتقالات ذهابًا وإيابًا قبل أن يتم تسليمها."
            ],
            secure: "آمن",
            tooSensitive: "حساس جدًا",
            symmetricEncryption: "SYMMETRIC\nENCRYPTION"
        },
        Scene8: {},
        Scene9: {
            memoryGameName: "MEMORY\nSPIES",
            shareScore: "شارك أفضل نتيجتك!",
            spyTutorialText: "كنت أمزح فقط...",
            tryAgain: "حاول مرة أخرى"
        },
        Scene10: {
            text: [
                "كانت الحاجة في المجتمع الحديث إلى طريقة تشفير أسرع وأكثر أمانًا. في السبعينيات، حصل التشفير غير المتماثل على تحديث كبير بفضل فكرة ثورية. بدلاً من استخدام قفلين، يمكنك إرسال قفل واحد تمتلك المفتاح الخاص به لفكه.",
                "تخيل أن 'أزرق' يريد التواصل مع صديقه 'أحمر'. يقوم أزرق بنسخ قفله ثم يجعل هذه النسخة عامة لكي يراها الجميع بينما يتم الاحتفاظ بالمفتاح لفكه سريًا وآمنًا.",
                "أحمر ينسخ التصميم ويعيد إنشاء نسخة من قفل أزرق، ثم يقوم أحمر بتأمين وإرسال رسالة إلى أزرق مع العلم أن لديه بالفعل المفتاح لفكها.",
                "القفل والمفتاح هما في الواقع عددان كبيران جدًا. القفل المتاح للجميع يسمى المفتاح العام والمفتاح المقابل الذي يبقى سريًا يسمى المفتاح الخاص.",
                "الأرقام العامة والخاصة تشترك في رابطة رياضية خاصة تتيح لهما تشفير الرسائل وفك تشفيرها بسلاسة. حتى مع معرفة الرياضيات وراء هذه العمليات وأن 'القفل' الخاص بك يتم إتاحته للجميع، يبقى التشفير الحديث غير قابل للكسر تقريبًا.",
                "هذا هو التشفير غير المتماثل الحديث، وتحديدًا أحد أكثر أنواعه شيوعًا، RSA. العودة إلى نظام القفل الواحد جعلت عملية التبادل أبسط بكثير وحدثت ثورة في الإنترنت، مما أتاح الاتصال السريع والآمن.",
                "منذ السبعينيات، تطور التشفير مع خوارزميات أكثر صعوبة في الكسر. RSA وSHA والآن ECC ظهرت كواحدة من أهم التقنيات التي تم تطويرها على الإطلاق."
            ],
            publicKey: "المفتاح العام",
            privateKey: "المفتاح الخاص",
            secure: "آمن",
            fast: "سريع",
            symmetricEncryption: "SYMMETRIC\nENCRYPTION",
            modern: "MODERN"
        },
        Scene11: {},
        Scene12: {
            bulletHellGameName: "ENCRYPTED\nESCAPE",
            shareScore: "شارك أفضل نتيجتك!",
            tutorial: "اضغط واستمر\nللتحرك وإطلاق النار!",
            tryAgain: "حاول مرة أخرى"
        },
        Scene13: {}
    },
    tr: {
        Scene1: {
            text: [
                "Şifreleme, bilgilerimizi güvende ve emniyette tutmaya yardımcı olan bir teknolojidir. Şimdiye kadar geliştirilmiş en önemli icatlardan biridir ve her gün ona güveniyoruz.",
                "Hackerlar ve büyük şirketler gibi kötü niyetli aktörler sürekli olarak bilgilerimize ulaşmaya çalışıyor, ancak şifreleme bilgileri okunamaz ve erişilemez hale getiriyor."
            ]
        },
        Scene2: {
            text: [
                "İnterneti birbirimizle paylaştığımız için, verilerimizi bu düşmanların gözünden kaçırmamız mümkün değil; bunun yerine, yalnızca bizim çözebileceğimiz bir şekilde karıştırıyoruz.",
                "İnternet, dünyanın dört bir yanına veri göndermemizi sağlayan geniş bir kablo ve sunucu ağıdır.",
                "Birine internet üzerinden veri gönderdiğimizde, sayısız kablo ve sunucudan geçer ve yol boyunca yüzlerce durak yapar.",
                "Bir arkadaşınıza mektup gönderdiğinizi hayal edin. Mektup çeşitli depolardan ve depolama alanlarından geçerken, oradaki herhangi biri, bilgilerinizi çalmak isteyen birisi, zarfı açıp mesajınızı okuyabilir.",
                "Şifreleme, mesajımızı karıştırarak ve okunamaz hale getirerek bu sorunu tamamen ortadan kaldırır — yalnızca anahtarı olanlar için erişilebilir hale gelir."
            ],
            data: "data"
        },
        Scene3: {},
        Scene4: {
            text: [
                "Dersimize, Antik Spartalılar'dan ve ilk şifreleme yöntemlerinden birini nasıl geliştirdiklerinden öğrenerek başlayalım.",
                "Sparta orduları daha fazla toprak fethederken, birbirleri arasında koordinasyonu sağlamak için birçok mesaj gönderilmesi gerekiyordu. Ancak düşmanlar bu habercileri fark etti, mesajları engellemeye ve hassas planları çalmaya başladı.",
                "Savaşlar kaybediliyordu, bu yüzden Spartalılar ilk şifreleme yöntemlerinden birini icat ettiler: Skytale. Mesajları kolayca şifreleyip çözmeyi sağlayan dahiyane bir araç.",
                "Spartalı generaller, deri bir şeridi bir tahta Skytale etrafına sarar ve planlarını deri bantlarının üzerine yazarlardı.",
                "İlginç bir şekilde, deri şerit, üzerine yazıldığı Skytale'den çıkarıldığında rastgele bir harf dizisi gibi görünüyordu. Bu, orijinal Skytale olmadan mesajı çözmeyi zorlaştırıyordu.",
                "Haberciler, gizli mesajı daha da gizlemek için onu bir kemer gibi kamufle ederek, düşman hatlarının ötesine 'anlamsız' bir tahta çubuk taşıyarak teslim etmeyi başardılar. Skytale iyi çalıştı ve Spartalı ordulara üstünlük sağladı.",
                "Bu, simetrik şifrelemedir; bir kilit ve bir anahtara dayanan bir sistemdir. Bir şeyi kilitler ve size gönderirsem, onu açmanız için anahtarı da göndermem gerekir. Mesajları şifrelemek ve çözmek hızlı ve basittir.",
                "Ancak Spartalılar için simetrik şifrelemenin büyük bir kusuru vardı. Düşmanlar, ordular arasında gönderilen şifreli kemerleri ve tahta çubukları fark etti ve mesajları kendileri çözmeye başladı.",
                "Bu durum, simetrik şifrelemenin en büyük sorununu vurgular: karışık mesajı çözmek için anahtarın bir noktada gönderilmesi gerekir. Bu, uzun süre çözülemez bir sorun gibi göründü — ta ki 20. yüzyıla kadar."
            ],
            fast: "HIZLI",
            dialog1: "Bunlar düşman planları mı?!",
            dialog2: "Hiçbir düşman planı tespit edilmedi...",
            dialog3: "Devam edin!",
            dialog4: "Bekleyin!",
            dialog5: "Bu bir Skytale!",
            insecure: "GÜVENSİZ",
            encrypted: "ŞİFRELİ",
            scytaleMessage: "IMMEDIATELY SEND TROOPS TO THE NORTHERN FLANK",
            symmetricEncryption: "SYMMETRIC\nENCRYPTION"
        },
        Scene5: {},
        Scene6: {
            messageDelivered: "MESAJ TESLİM EDİLDİ!",
            endlessRunnerGameName: "SCYTALE\nRUN",
            shareScore: "EN İYİ SKORUNU PAYLAŞ!",
            tutorial: "ŞERİTLERİ DEĞİŞTİRMEK İÇİN DOKUN",
            tutorial2: "MUHAFIZLARDAN KAÇ VE SKYTALE TESLİM ET!",
            tryAgain: "TEKRAR DENE",
            luckRollSuccess: "Temizsin.\nDevam et.",
            luckRollFail: "BU BİR SKYTALE!\nBURAYA GERİ GEL!",
            coinFlipHeader: "KADER KARAR VERSİN!",
            coinFlipSuccess: "GÜVENDESİN!",
            coinFlipFail: "MESAJI\nBULDULAR!"
        },
        Scene7: {
            slow: "YAVAŞ",
            text: [
                "Zaman içinde ilerleyelim ve Soğuk Savaş döneminde ikinci tür şifrelemeyi öğrenelim. ABD ile Sovyetler Birliği arasındaki hassas dengeyi bozabilecek bir hatanın yapılabileceği bir dönem.",
                "Hayal edin ki biri ABD'den diğeri Sovyetler Birliği'nden olan iki rakip casus, bir barış anlaşması oluşturmak için birlikte çalışıyor.",
                "Güven azdı ve hiçbir taraf simetrik şifrelemeye güvenmek istemedi. Anahtarı birbirlerine göndermek riskliydi ve kaybolması dünya çapında bir felakete dönüşebilirdi. Bunun yerine dahiyane bir plan geliştirdiler.",
                "Amerikalı casus, imzalı barış anlaşmasını sağlam bir çantanın içine yerleştirip bir asma kilit ile kilitledi ve park bankına bıraktı.",
                "Sovyet casusu, kilitli çantayı aldı ve Amerikan asma kilidinin yanına kendi asma kilidini taktı. Ardından, çift kilitli çantayı aynı park bankına bıraktı.",
                "Amerikalı casus, çantayı aldı, kendi kilidini çıkardı ve hala kilitli halde park bankına geri koydu.",
                "Son olarak, Sovyet casusu çantayı son kez aldı, kendi kilidini çıkardı ve imzalı barış anlaşmasını ortaya çıkardı — güvenli bir şekilde teslim edilmiş ve savaşı bitirmeye hazır.",
                "Bu, asimetrik şifrelemedir; simetrik şifrelemenin en büyük kusurunu, iki kilit kullanarak ve hiç anahtar göndermeyerek çözer. Çok daha güvenlidir.",
                "Ancak bu ekstra güvenlik bir bedelle gelir — bu yöntem yavaştır. Bu yöntemle bir şeyi güvenli bir şekilde göndermek için mesajın üç kez gidip gelmesi gerekir."
            ],
            secure: "GÜVENLİ",
            tooSensitive: "Çok hassas",
            symmetricEncryption: "SYMMETRIC\nENCRYPTION"
        },
        Scene8: {},
        Scene9: {
            memoryGameName: "MEMORY\nSPIES",
            shareScore: "EN İYİ SKORUNU PAYLAŞ!",
            spyTutorialText: "Sadece şaka yapıyordum...",
            tryAgain: "TEKRAR DENE"
        },
        Scene10: {
            text: [
                "Modern toplum, daha hızlı ve aynı derecede güvenli bir şifreleme yöntemi gerekiyordu. 1970'lerde, asimetrik şifreleme, devrim niteliğinde bir fikir sayesinde büyük bir güncelleme aldı. İki kilit yerine, sahip olduğunuz bir kilidi gönderebilirsiniz.",
                "Mavi'nin arkadaşı Kırmızı ile iletişim kurmak istediğini hayal edin. Mavi, kendi kilidini kopyalar ve bu kopyayı herkesin görebileceği şekilde halka açık yapar, kilidi açmak için gereken anahtar ise gizli ve güvenli tutulur.",
                "Kırmızı, tasarımı kopyalar ve Mavi'nin kilidinin bir kopyasını yeniden oluşturur, ardından mesajı güvence altına alır ve Mavi'ye gönderir, Mavi'nin bunu açmak için anahtara zaten sahip olduğunu bilerek.",
                "Kilidi ve anahtarı, aslında inanılmaz derecede büyük iki sayıdır. Herkese açık olan 'kilidiniz' bir genel anahtar olarak adlandırılır ve gizli tutulan karşılık gelen 'anahtar' özel anahtar olarak adlandırılır.",
                "Genel ve özel anahtar numaraları, mesajları sorunsuz bir şekilde şifrelemek ve çözmek için özel bir matematiksel bağa sahiptir. Bu süreçlerin ardındaki matematik genel bilgi olsa ve 'kilidiniz' halka açık hale gelse bile, modern şifreleme neredeyse kırılmazdır.",
                "Bu, modern asimetrik şifrelemedir; özellikle en yaygın türlerinden biri olan RSA. Tek kilitli bir sisteme geri dönülmesi, değişim sürecini önemli ölçüde basitleştirdi ve interneti devrimleştirdi, hızlı ve güvenli iletişim sağladı.",
                "1970'lerden bu yana, şifreleme daha da kırılmaz algoritmalarla gelişti. RSA, SHA ve şimdi ECC, şimdiye kadar geliştirilmiş en önemli teknolojilerden bazıları olarak ortaya çıktı."
            ],
            publicKey: "GENEL ANAHTAR",
            privateKey: "ÖZEL ANAHTAR",
            secure: "GÜVENLİ",
            fast: "HIZLI",
            symmetricEncryption: "SYMMETRIC\nENCRYPTION",
            modern: "MODERN"
        },
        Scene11: {},
        Scene12: {
            bulletHellGameName: "ENCRYPTED\nESCAPE",
            shareScore: "EN İYİ SKORUNU PAYLAŞ!",
            tutorial: "HAREKET ETMEK VE ATEŞ ETMEK İÇİN DOKUN VE BASILI TUT!",
            tryAgain: "TEKRAR DENE"
        },
        Scene13: {}
    }
};
