<template>
    <ion-page>
        <SubscriptionNav />
        <ion-content>
            <SubscriptionCard @completedSubscription=handleSubscription() />
        </ion-content>
    </ion-page>
</template>

<script lang="ts">
import { useUserStore } from '../../store/userStore';
import SubscriptionNav from '../../components/nav/SubscriptionNav.vue';
import SubscriptionCard from '../../components/subscription/SubscriptionCard.vue';

export default {
    components: {
        SubscriptionNav,
        SubscriptionCard
    },
    methods: {
        handleSubscription() {
            useUserStore().checkUserPostValidation();
            this.$router.push({ name: "index" });
        }
    }
}
</script>

<style scoped></style>
