<template>
    <ion-modal :isOpen="isOpen" :backdrop-dismiss="false" @didDismiss="closeModal(false)">
        <ion-content :forceOverscroll="false">
            <ion-toolbar class="lattice-modal ion-padding-top">
                <ion-buttons slot="start">
                    <ion-button id="click-trigger">
                        <GlobalIcon :size="'large'" :iconName="alertCircleOutline" />
                    </ion-button>
                </ion-buttons>
            </ion-toolbar>
            <ion-popover class="patch-notes" trigger="click-trigger" trigger-action="click">
                <PatchNotes />
            </ion-popover>
            <ArkitectLogo class="ArkitectLogo"></ArkitectLogo>
            <CareerSelector @close="closeModal(true)"></CareerSelector>
        </ion-content>
    </ion-modal>
</template>

<script lang="ts">
import GlobalIcon from '../components/GlobalIcon.vue';
import { close, alertCircleOutline } from 'ionicons/icons';
import { useModalStore } from '../store/modalStore';
import TextBox from './welcome/TextBox.vue';
import ArkitectLogo from './welcome/ArkitectLogo.vue';
import PatchNotes from './modal/PatchNotes.vue';
import CareerSelector from './welcome/CareerSelector.vue';

const mStore = useModalStore();

export default {
    name: 'WelcomeModal',
    emits: ['close'],
    setup() {
        return {
            close,
            alertCircleOutline
        }
    },
    components: {
        TextBox,
        ArkitectLogo,
        GlobalIcon,
        PatchNotes,
        CareerSelector
    },
    data() {
        return {
            backgroundColors: ['#FFFFFF', '#FFFFFF', '#FFFFFF'],
            cardImages: ['../welcome/learn.png', '../welcome/certified.png', '../welcome/affordable.png']
        };
    },
    props: {
        isOpen: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        closeModal(openNode: boolean) {
            mStore.toggleWelcomeModal(false);
            if (openNode) {
                setTimeout(() => {
                    this.$emit('close');
                }, 1250);
            }
        }
    }
}
</script>

<style scoped>
ion-modal {
    padding-top: 0%;
    padding-bottom: 0%;
    overflow: hidden;
}


ion-content {
    --background: none;
    /* background-image: url('../assets/backgrounds/welcome.png'); */
}

.container {
    margin-top: -15px;
}

.swiper {
    width: 100%;
    height: 47rem;
}

.slide-layout {
    display: flex;
    flex-direction: column;
}

.ArkitectLogo {
    margin-top: 1.5rem;
}

.TextBox {
    position: absolute;
    bottom: 5%;
    width: 90%;
    left: 50%;
    transform: translateX(-50%);
}

.patch-notes {
    --width: 80%;
    --height: 25rem;
}
</style>