export default defineI18nLocale(async (locale) => {
    return {
        nav: {
            back: "العودة",
            save: "حفظ",
            submit: "إرسال"
        },
        home: {
            login: {
                title: "تسجيل الدخول",
                header: "تسجيل الدخول",
                welcome_back: "مرحبا بعودتك!",
                email: "البريد الإلكتروني",
                password: "كلمة المرور",
                login: "تسجيل الدخول",
                or: "أو",
                no_account: "لا تملك حساب؟",
                signup: "إنشاء حساب",
                forgot_password: "نسيت كلمة المرور؟",
                reset_password: "إعادة تعيين كلمة المرور"
            },
            signup: {
                title: "إنشاء حساب",
                questline_selection: {
                    header: "لنبدأ باختيار مسار المهام.",
                    text: "المسار الذي تختاره سيوجه الدروس التي تتلقاها.",
                    coming_soon: "قريبًا!"
                },
                email: {
                    header: "لنقم بتسجيلك!",
                    text: "بعد ذلك، سنحتاج إلى بريد إلكتروني.",
                    placeholder: "البريد الإلكتروني",
                    next: "التالي",
                    or: "أو"
                },
                details: {
                    header: "نحتاج بعض التفاصيل الإضافية.",
                    name: "الاسم",
                    name_placeholder: "الاسم (الأول الأخير)",
                    password: {
                        title: "كلمة المرور",
                        placeholder: "كلمة المرور",
                        verify: "تأكيد كلمة المرور",
                        password_requirements:
                            "على الأقل 8 أحرف، رقم واحد، حرف كبير واحد."
                    },
                    signup: {
                        header: "نحن نُسجلكم...",
                        text: "يجب أن يستغرق هذا فقط لحظة..."
                    }
                },
                signin: {
                    header: "تمسكوا جيداً!",
                    text: "نحن نقوم بتسجيل دخولكم..."
                },
                subscription: {
                    maybe_later: "ربما لاحقاً"
                },
                action: {
                    header: "نأمل أنك تعلمت شيئًا!",
                    sub: {
                        1: "اشترك ",
                        2: "لمواصلة التعلم",
                        3: " وتقدم في رحلتك مع CompTIA A+ اليوم!"
                    },
                    button: "ابدأ تجربتك المجانية"
                }
            },
            next_lesson: "الدرس التالي",
            launch: "ابدأ",
            privacy: "سياسة الخصوصية وشروط الخدمة",
            subscribe: "اشترك للمزيد",
            node_unavailable: "العقدة غير متوفرة",
            signup_to_access: "سجل للوصول",
            start_node: "ابدأ",
            recenter: "إعادة تمركز"
        },
        end_card: {
            header: "الدرس مكتمل:",
            progress_text: "تقدم سلسلة المهام:",
            earned: "مكتسب",
            streak: "سلسلة متتالية",
            total: "المجموع",
            shop_ship: "استخدم هذه لشراء السفن من متجر السفن!",
            return: "العودة إلى الخريطة"
        },
        streak: {
            header: "متابعة",
            message: "تتم إعادة تعيين المتابعات عند تفويتك ليوم",
            days: "أيام"
        },
        phaserfeedback: {
            title: "التغذية الراجعة",
            header: "تغذية راجعة عن الدرس",
            paragraph:
                "نقدر آرائك! يرجى مشاركة أفكارك حول هذا الدرس أو الشرائح المحددة.",
            share: "شارك أفكارك:",
            textarea_placeholder: "ماذا يدور في ذهنك؟",
            rating_placeholder: "كيف تقيم تجربتك؟",
            submit: "إرسال التغذية الراجعة"
        },
        uifeedback: {
            title: "التغذية الراجعة",
            header: "نود أن نسمع منك!",
            paragraph:
                "مدخلاتك تدفع ابتكارنا. مشاركة آرائك تساعدنا على تحسين Arkitect، مما يجعل كل درس أكثر تفاعلية وواجهتنا أكثر سهولة في الاستخدام. أخبرنا بما تفكر فيه!",
            share: "شارك أفكارك:",
            textarea_placeholder: "ماذا يدور في ذهنك؟",
            submit: "إرسال التغذية الراجعة"
        },
        friends: {
            title: "الأصدقاء",
            case_sensitive: "أسماء المستخدمين حساسة لحالة الأحرف",
            friends_list: "قائمة الأصدقاء",
            incoming_requests: "الطلبات الواردة",
            outgoing_requests: "الطلبات الصادرة",
            add_user: "إضافة مستخدم",
            remove_user: "إزالة",
            accept_user: "قبول",
            reject_user: "رفض",
            cancel_request: "إلغاء الطلب"
        },
        shop: {
            title: "متجر السفن",
            welcome: "مرحبا بكم في المتجر!",
            selected: "مختار",
            select: "اختيار",
            owned: "مملوك",
            purchase: "شراء",
            unavailable: "غير كافٍ"
        },
        patchnotes: {
            title: "ملاحظات التحديث",
            version: "الإصدار",
            changes: [
                "✨ <b>تجديد كامل للتصميم</b><br>&nbsp;&nbsp;- قمنا بتحديث التطبيق بالكامل بموضوع جديد، ألوان محدثة، ومظهر عام محسن.",
                "⚙️ <b>تحديث شامل للواجهة الخلفية والأمامية</b><br>&nbsp;&nbsp;- أجرينا تحديثات كبيرة على كل من الواجهة الخلفية والأمامية—تمت إعادة كتابة حوالي 50٪ من الوظائف الأساسية للتطبيق لتحسين الأداء وتجربة المستخدم.",
                "📚 <b>إطلاق درس جديد: التشفير</b><br>&nbsp;&nbsp;- درس جديد بالكامل حول التشفير أصبح متاحًا الآن. يتميز هذا الدرس بأسلوب درس محدث يتماشى مع التصميم الجديد للتطبيق.",
                "👋 <b>تحديث الوصول للضيوف</b><br>&nbsp;&nbsp;- تم تحديث الدرس المتاح للضيوف ليشمل محتوى التشفير الجديد.",
                "🌐 <b>إطلاق تطبيق الويب بدعم PayPal</b><br>&nbsp;&nbsp;- يسعدنا أن نعلن عن إطلاق تطبيق الويب الخاص بنا على <a href='https://learn.skillrocket.ca' target='_blank'>learn.skillrocket.ca</a>، والذي يدعم الآن مدفوعات PayPal."
            ]
        },
        subscriptions: {
            early_access: "الوصول المبكر",
            unlimited: "الوصول غير المحدود",
            new_content: "دروس جديدة كل أسبوع",
            review: "مراجعة باستخدام بطاقات تعليمية وفحوص مخصصة",
            prep: "التحضير لشهادات شهيرة",
            trial: "... والأسبوع الأول مجاني",
            new: "(للعملاء الجدد)",
            week: "تجربة لمدة أسبوع واحد +",
            button: {
                already_subscribed: "ماشترك بالفعل",
                subscribe: "سجل الآن"
            },
            billing:
                "لن يتم تحصيل أي رسوم إذا قمت بالإلغاء قبل نهاية فترة التجربة المجانية. بعد الفترة التجريبية، تبدأ عملية الفوترة وتتكرر في بداية كل فترة فوترة. يمكنك الإلغاء في أي وقت في",
            manage: "إدارة الاشتراك",
            unavailable: {
                title: "عذراً!",
                message:
                    "لا تتوفر الاشتراكات حاليًا عبر الإنترنت. لتسجيل اشتراك، يرجى زيارة متجر التطبيقات الخاص بك وتحميل تطبيقنا!"
            },
            policy: {
                text: "بالتسجيل، أنت توافق على",
                link: "شروط الخدمة وسياسة الخصوصية الخاصة بنا"
            }
        },
        welcomemodal: {
            headers: [
                "تعلم بسهولة",
                "احصل على شهادة تقنية اليوم",
                "بأسعار معقولة ومحمول"
            ],
            texts: [
                "تعلم التكنولوجيا من خلال دروس سهلة الفهم واختبارات وبطاقات تعليمية متسقة.",
                "نجعل الحصول على شهادة تقنية والحصول على تلك الوظيفة في سوق العمل التنافسي اليوم أمرًا سهلاً.",
                "لا فصول دراسية، لا كتب ودورات باهظة الثمن. دروس ممتعة وسهلة الوصول في متناول يدك."
            ],
            slide1: {
                title: "مرحبًا بك في SkillRocket!",
                text: "لنبدأ باختيار مسار مهني."
            },
            slide2: {
                title: "رائع، الآن لنختر شهادة!",
                text: "جرّب درسًا مجانيًا من هذه الشهادة المهنية قبل بدء فترة التجربة المجانية لمدة 7 أيام!"
            },
            slide3: {
                title: "لنبدأ الآن!",
                text: "اختر سفينة وانطلق في درسك الأول!",
                sub: "قم بالتسجيل وافتح المزيد من السفن أثناء إكمال الدروس."
            }
        },
        dashboard: {
            title: "الإعدادات",
            account: {
                title: "الحساب",
                userInfo: {
                    title: "معلومات المستخدم",
                    header: "الحساب",
                    account_info: "معلومات الحساب",
                    username: {
                        title: "اسم المستخدم",
                        header: "اسم المستخدم",
                        enter_username: "الرجاء إدخال اسم مستخدم جديد أدناه:",
                        username_input: {
                            status_message:
                                "ابدأ الكتابة للتحقق من توفر اسم المستخدم.",
                            generate_username: "توليد اسم المستخدم"
                        }
                    },
                    email: {
                        title: "البريد الإلكتروني",
                        header: "البريد الإلكتروني",
                        enter_email: "الرجاء إدخال بريد إلكتروني جديد أدناه:"
                    }
                },
                subscription: "الاشتراك",
                preferences: {
                    title: "التفضيلات",
                    header: "تفضيلات التطبيق",
                    sub_header: "التفضيلات",
                    language: "اللغة",
                    dark: "الوضع الداكن",
                    haptics: "المؤثرات اللمسية",
                    audio: "الصوت",
                    narration: "السرد"
                }
            },
            support: {
                title: "الدعم",
                support: "الدعم",
                contact: "اتصل بنا",
                in_touch: "تواصل معنا",
                fill_form_request: "يرجى ملء النموذج أدناه للتواصل معنا.",
                return_email: "البريد الإلكتروني للرد",
                question: "ما الذي تحتاج مساعدة به؟",
                response_placeholder: "اكتب رسالتك هنا..."
            },
            login: {
                title: "تسجيل الدخول",
                delAccount: {
                    title: "حذف الحساب",
                    header: "حذف الحساب",
                    notice: "تنبيه",
                    warning: "بمجرد حذف حسابك، سيتم إزالته نهائيًا من نظامنا.",
                    enter_email: "الرجاء إدخال بريدك الإلكتروني أدناه:",
                    delete: "حذف"
                },
                logout: "تسجيل الخروج"
            }
        }
    };
});
